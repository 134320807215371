import React, { useState } from 'react'
import styles from './InvoiceEditor.module.css'

const InvoiceEditor = (props) => {
    const { isOpen, toggleInvoiceEditor } = props
    const [invoiceDate, setInvoiceDate] = useState('')
    const [dueDate, setDueDate] = useState('')
    const [bankAccountDetails, setBankAccountDetails] = useState('ICICI')

    const [tncTxt, setTncTxt] = useState(`
        Subject to our home Jurisdiction.
        Our Responsibility Ceases as soon as goods leaves our Premises.
        Goods once sold will not taken back.
        Delivery Ex-Premises.`
    )



    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            Invoice Editor
                        </div>
                        <div className={styles.mainBody}>

                            <div className={styles.eachSectionWrapper}>
                                <div className={styles.eachDateWrapper}>
                                    <div>
                                        Invoice Date
                                    </div>
                                    <div>
                                        <input type="date" />
                                    </div>
                                </div>
                                <div className={styles.eachDateWrapper}>
                                    <div>
                                        Due Date
                                    </div>
                                    <div>
                                        <input type="date" />
                                    </div>
                                </div>

                                <div className={styles.eachDateWrapper}>
                                    <div>

                                        Bank Account Details
                                    </div>
                                    <div>
                                        <select>
                                            <option>ICICI</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.eachSectionWrapper}>
                                <div className={styles.tncTitle}>Terms & Conditions</div>
                                <div className={styles.tncForm}>
                                    <textarea rows={6}
                                        placeholder="Enter terms and conditions..."
                                        value={tncTxt}
                                        onChange={(e) => setTncTxt(e.target.value)}
                                    >

                                    </textarea>
                                </div>
                            </div>

                        </div>
                        <div className={styles.mainFooter}>
                            <div className={styles.actionBtnWrapper}>
                                <button onClick={() => toggleInvoiceEditor()}>Cancel</button>
                                <button className={styles.updateBtn}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InvoiceEditor