import React, { useContext, useEffect, useState } from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import EachRowAllProducts from '../../Components/Marketplace/EachRowAllProducts'
import EditProduct from '../../Components/Marketplace/EditProduct'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './ViewProducts.module.css'

const ViewProducts = () => {
    const authCtx = useContext(AuthContext)
    const [allProducts, setAllProducts] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [editingItem, setEditingItem] = useState(null)


    const editingItemHandler = (k) => {
        console.log(k)
        setEditingItem(k)
    }
    const closeEditingItem = () => setEditingItem(null)

    const getEcomProducts = async () => {
        setIsLoading(true)
        const getEcomProductsResponse = await fetch(BASE_URL_API + "/getEcomProducts",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getEcomProductsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getEcomProductsRespo = await getEcomProductsResponse.json()
            if (getEcomProductsRespo.status === "success") {
                setAllProducts(getEcomProductsRespo.response)
            } else {
                if (getEcomProductsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getEcomProductsRespo)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        getEcomProducts()
    }, [])


    return (
        <div>
            <MainHeader />
            {!!editingItem && <EditProduct onCompletion={getEcomProducts} isOpen={!!editingItem} item={editingItem} closeEditingItem={closeEditingItem} />}
            <div className={styles.mainContentWrapper}>
                {isLoading ?
                    <div>
                        Hold on!
                        Collecting all products!
                    </div>
                    :
                    <div>
                        <div className={styles.tableHeader}>
                            <div className={styles.id}>
                                #
                            </div>
                            <div className={styles.title}>
                                Title
                            </div>
                            <div className={styles.brand}>
                                Brand
                            </div>
                            <div className={styles.category}>
                                Category
                            </div>
                            <div className={styles.description}>
                                Description
                            </div>
                            <div className={styles.pointsRequired}>
                                Points Required
                            </div>
                            <div>
                                Action
                            </div>
                        </div>
                        <div className={styles.tableBody}>
                            {allProducts?.map((item, index) => (
                                <EachRowAllProducts key={index} item={item} editingItemHandler={editingItemHandler} />
                            ))}
                            <div className={styles.thatsAll}>
                                That's all!
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ViewProducts