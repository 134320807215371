import React, { useContext, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './EachProductRow.module.css'


const EachProductRow = (props) => {
    const { count, item } = props
    const authCtx = useContext(AuthContext)
    const [isMapping, setIsMapping] = useState(false)

    const mapQrWithProduct = async () => {
        setIsMapping(true)
        console.log(item?.productIdentifier)
        const mapQrWithProductResponse = await fetch(BASE_URL_API + "/mapQrWithProduct",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    productIdentifier: item?.productIdentifier
                })
            });

        if (!mapQrWithProductResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const mapQrWithProductRespo = await mapQrWithProductResponse.json()
            if (mapQrWithProductRespo.status === "success") {

            } else {
                if (mapQrWithProductRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(mapQrWithProductRespo)
        }
        setIsMapping(false)
    }




    return (
        <div className={styles.mainWrapper} >
            <div className={styles.sn}>{count + 1}. </div>
            <div className={styles.productIdentifier}>{item?.productIdentifier}</div>
            <div className={styles.productTitle}>{item?.productTitle}</div>
            <div className={styles.productDescription}>{item?.productDescription}</div>
            <div className={styles.codeIdentifier}>{item?.brandName}</div>
            <div className={styles.mrp}>{item?.mrp}</div>
            <div className={styles.actionWrapper}>
                {isMapping === true ?
                    <button>Mapping...</button>
                    :
                    <button onClick={() => mapQrWithProduct()}>Map</button>
                }
            </div>
        </div>
    )
}

export default EachProductRow