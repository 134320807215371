import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './GenerateQRCodesNow.module.css'


const GenerateQRCodesNow = (props) => {
    const { onSuccess } = props
    const authCtx = useContext(AuthContext)
    const [codeQuantity, setCodeQuantity] = useState(0)
    const [pointsValue, setPointsValue] = useState(0)
    const [isGenerating, setIsGenerating] = useState(false)

    const [allProducts, setAllProducts] = useState([])
    const [gettingAllProducts, setGettingAllProducts] = useState(false)
    const [activeProduct, setActiveProduct] = useState('')


    const generateQRCodesHandler = async () => {
        if (codeQuantity !== 0) {
            setIsGenerating(true)
            const generateQRCodesResponse = await fetch(BASE_URL_API + "/generateQRCodes",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        codeQuantity: codeQuantity,
                        pointsValue: pointsValue,
                        productIdentifier : activeProduct
                    })
                })

            if (!generateQRCodesResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const generateQRCodesRespo = await generateQRCodesResponse.json()
                if (generateQRCodesRespo.status === "success") {
                    onSuccess()
                } else {
                    if (generateQRCodesRespo.message === "token Expired, please login again!") {
                        authCtx.logout()
                    } else {
                        alert(generateQRCodesRespo.message)
                    }
                }
                console.log(generateQRCodesRespo)
            }
            setIsGenerating(false)
        } else {
            console.log(codeQuantity)
            alert("Please select quantity of codes to be generated!")
        }
    }




    const getAllProducts = async () => {
        setGettingAllProducts(true)
        const getAllProductsResponse = await fetch(BASE_URL_API + "/getAllProducts",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllProductsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllProductsRespo = await getAllProductsResponse.json()
            if (getAllProductsRespo.status === "success") {
                setAllProducts(getAllProductsRespo.response)
            } else {
                if (getAllProductsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllProducts([])
                }
            }
        }
    }

    useEffect(() => {
        getAllProducts()
    }, [])



    return (
        <div className={styles.mainContainer}>
            <div>
                Generate QR Codes in Bulk
            </div>
            <div className={styles.generateCardWrapper}>
                <div className={styles.generateRow}>
                    <div>
                        Generate
                    </div>
                    <div className={styles.codeQuantityWrapper}>
                        <select onChange={(e) => setCodeQuantity(e.target.value)}>
                            <option value={0} >0</option>
                            <option value={10} >10</option>
                            <option value={20} >20</option>
                            <option value={50} >50</option>
                            <option value={80} >80</option>
                            <option value={100} >100</option>
                            <option value={150} >150</option>
                            <option value={200} >200</option>
                            <option value={250} >250</option>
                            <option value={500} >500</option>
                        </select>
                    </div>
                </div>
                <div>
                    QR Codes
                </div>
                <div className={styles.pointValueRow}>
                    with point value of
                    <input placeholder="Enter points value" value={pointsValue} onChange={(e) => setPointsValue(e.target.value)} />
                </div>

                <div className={styles.productRow}>
                    for product
                    <div>
                        <select onChange={(e) => setActiveProduct(e.target.value)}>
                            <option value="BLANK">None</option>
                            {allProducts?.map((item, index) => (
                                <option key={index} value={item?.productIdentifier} >{item?.productTitle}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className={styles.generateNowBtnWrapper}>
                    {isGenerating === false ?
                        <button onClick={() => generateQRCodesHandler()} >Generate Now</button>
                        :
                        <button >Generating codes...</button>
                    }
                </div>
            </div>

        </div>
    )
}

export default GenerateQRCodesNow