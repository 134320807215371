import React, { useContext, useState } from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './OnboardNewClients.module.css'

const OnboardNewClients = () => {
    const authCtx = useContext(AuthContext)
    const [brandName, setBrandName] = useState('')
    const [firmName, setFirmName] = useState('')
    const [gstNumber, setGstNumber] = useState('')
    const [cin, setCin] = useState('')
    const [officeAddress, setOfficeAddress] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const resetForm = () => {
        setBrandName('')
        setFirmName('')
        setGstNumber('')
        setCin('')
        setOfficeAddress('')
    }

    const onboardClient = async () => {
        if (brandName !== '' && firmName !== '' && officeAddress !== '') {
            setIsLoading(true)
            const onboardClientResponse = await fetch(BASE_URL_API + "/onboardClient",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        brandName: brandName,
                        firmName: firmName,
                        gstNumber: gstNumber,
                        cin: cin,
                        officeAddress: officeAddress
                    })
                });

            if (!onboardClientResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const onboardClientRespo = await onboardClientResponse.json()
                if (onboardClientRespo.status === "success") {
                    resetForm()
                } else {
                    if (onboardClientRespo.message === "token Expired, please login again!") {
                        authCtx.logout()
                    } else {

                    }
                }
            }
            setIsLoading(false)
        } else {
            alert("All fields are mandatory!")
        }

    }


    return (
        <div className={styles.mainWrapper}>
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.formContainer}>


                        <div className={styles.eachField}>
                            <div className={styles.fieldCaption}>

                            </div>
                            <div className={styles.eachFieldInput}>
                                <input
                                    placeholder="Please enter brand name" value={brandName}
                                    autoFocus onChange={(e) => setBrandName(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className={styles.eachField}>
                            <div className={styles.fieldCaption}>

                            </div>
                            <div className={styles.eachFieldInput}>
                                <input placeholder="Please enter firm name"
                                    value={firmName} onChange={(e) => setFirmName(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className={styles.eachField}>
                            <div className={styles.fieldCaption}>

                            </div>
                            <div className={styles.eachFieldInput}>
                                <input placeholder="Please enter gst number"
                                    value={gstNumber} onChange={(e) => setGstNumber(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={styles.eachField}>
                            <div className={styles.fieldCaption}>

                            </div>
                            <div className={styles.eachFieldInput}>
                                <input placeholder="Please enter CIN"
                                    value={cin}
                                    onChange={(e) => setCin(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={styles.eachField}>
                            <div className={styles.fieldCaption}>

                            </div>
                            <div className={styles.eachFieldInput}>
                                <textarea placeholder="Please enter office address"
                                    value={officeAddress}
                                    onChange={(e) => setOfficeAddress(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className={styles.submitBtn}>
                            {isLoading === true ?
                                <button >Creating...</button>
                                :
                                <button onClick={() => onboardClient()}>Submit</button>
                            }
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default OnboardNewClients