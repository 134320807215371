import React, { useContext, useEffect, useState } from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import EachSchemeOffer from '../../Components/SchemeNOffer/EachSchemeOffer'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './SchemesNOffers.module.css'

const SchemesNOffers = () => {
    const authCtx = useContext(AuthContext)
    const [allSchemes, setAllSchemes] = useState([])

    const getAllSchemes = async () => {
        const getAllSchemesResponse = await fetch(BASE_URL_API + "/getAllSchemes",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                })
            });

        if (!getAllSchemesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllSchemesRespo = await getAllSchemesResponse.json()
            if (getAllSchemesRespo.status === "success") {
                setAllSchemes(getAllSchemesRespo.response)
            } else {
                if (getAllSchemesRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllSchemes([])
                }
            }
            console.log(getAllSchemesRespo)
        }
    }

    useEffect(() => {
        getAllSchemes()
    }, [])



    return (
        <div>
            <MainHeader />
            <div className={styles.bodyWrapper}>
                <div className={styles.filtersWrapper}>
                    <div>
                        <button>All Schemes</button>
                    </div>
                    <div>

                    </div>
                </div>
                <div className={styles.tableWrapper}>
                    <div className={styles.tableHeader}>
                        <div className={styles.sn}>
                            #
                        </div>
                        <div className={styles.schemeBanner}>
                            Banner
                        </div>
                        <div className={styles.schemeTitle}>
                            Title
                        </div>
                        <div className={styles.description}>
                            Description
                        </div>
                        <div className={styles.startDate}>
                            Start Date
                        </div>
                        <div className={styles.endDate}>
                            End Date
                        </div>
                        <div className={styles.addedOn}>
                            Added On
                        </div>

                    </div>
                    <div className={styles.tableBody}>
                        {allSchemes?.map((item, index) => (
                            <EachSchemeOffer key={index} count={index + 1} item={item} />
                        ))}


                    </div>
                </div>
            </div>
        </div>
    )
}

export default SchemesNOffers