import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import InvoiceEditor from '../../Components/GenerateInvoice/InvoiceEditor'
import InvoiceGenerator from '../../Components/GenerateInvoice/InvoiceGenerator'
import MainHeader from '../../Components/Headers/MainHeader'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './OrderDetails.module.css'

const OrderDetails = () => {
    const { orderIdentifier } = useParams()
    const authCtx = useContext(AuthContext)

    const [cartItems, setCartItems] = useState([])
    const [orderDetails, setOrderDetails] = useState(null)
    const [totalOrderValue, setTotalOrderValue] = useState(0)
    const [statusToUpdate, setStatusToUpdate] = useState('')
    const [isEditingInvoice, setIsEditingInvoice] = useState(false)
    const [isGeneratingInvoice, setIsGeneratingInvoice] = useState(false)
    const toggleInvoiceGenerator = () => {
        setIsGeneratingInvoice(false)
    }

    const toggleInvoiceEditor = () => setIsEditingInvoice(false)

    const getBulkOrderCartStats = async () => {
        const getBulkOrderCartStatsResponse = await fetch(BASE_URL_API + "/getBulkOrderCartStats",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    orderIdentifier: orderIdentifier
                })
            });

        if (!getBulkOrderCartStatsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getBulkOrderCartStatsRespo = await getBulkOrderCartStatsResponse.json()
            if (getBulkOrderCartStatsRespo.status === "success") {
                setCartItems(getBulkOrderCartStatsRespo?.response)
            } else {
                if (getBulkOrderCartStatsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getBulkOrderCartStatsRespo)
        }
    }

    useEffect(() => {
        getPlacedOrderDetails()
        getBulkOrderCartStats()
    }, [])

    const getPlacedOrderDetails = async () => {
        const getPlacedOrderDetailsResponse = await fetch(BASE_URL_API + "/getPlacedOrderDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    orderIdentifier: orderIdentifier
                })
            });

        if (!getPlacedOrderDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getPlacedOrderDetailsRespo = await getPlacedOrderDetailsResponse.json()
            if (getPlacedOrderDetailsRespo.status === "success") {
                setOrderDetails(getPlacedOrderDetailsRespo?.response)
            } else {
                setOrderDetails(null)
            }
            console.log(getPlacedOrderDetailsRespo)
        }
    }

    const calculateOrderValue = () => {
        let total = 0
        cartItems?.map((item, index) => (
            total = total + parseFloat(item?.totalPrice)
        ))
        setTotalOrderValue(total)
    }

    const updateOrderStatus = async () => {
        if (statusToUpdate !== '') {
            const updateOrderStatusResponse = await fetch(BASE_URL_API + "/updateOrderStatus",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        statusToUpdate: statusToUpdate,
                        orderIdentifier: orderIdentifier
                    })
                });

            if (!updateOrderStatusResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const updateOrderStatusRespo = await updateOrderStatusResponse.json()
                if (updateOrderStatusRespo.status === "success") {
                    getPlacedOrderDetails()
                } else {

                }
                console.log(updateOrderStatusRespo)
            }
        } else {
            alert("Please select a status to update!")
        }
    }

    useEffect(() => {
        if (statusToUpdate !== '') {
            console.log("updating... ", statusToUpdate)
            updateOrderStatus()
        }
    }, [statusToUpdate])


    useEffect(() => {
        calculateOrderValue()
    }, [orderDetails])



    return (
        <div>
            <MainHeader />
            {isGeneratingInvoice &&
                <InvoiceGenerator
                    isGeneratingInvoice={isGeneratingInvoice}
                    clientDetails={orderDetails}
                    cartItems={cartItems}
                    orderIdentifier={orderIdentifier}
                    toggleInvoiceGenerator={toggleInvoiceGenerator}
                />
            }
            {isEditingInvoice && 
                <InvoiceEditor 
                    isOpen={isEditingInvoice}
                    orderDetails={orderDetails}
                    cartItems={cartItems}
                    orderIdentifier={orderIdentifier}
                    toggleInvoiceEditor={toggleInvoiceEditor}
                />
            }
            <div className={styles.mainContainer}>
                <div className={styles.mainHeaderWrapper}>
                    <div className={styles.topRowWrapper}>
                        <div >
                            Client & Order details
                        </div>
                        <div>
                            <div className={styles.currentStatus}>
                                {orderDetails?.currentStatus}
                            </div>
                        </div>
                    </div>
                    <div className={styles.headerContentWrapper}>
                        <div>
                            <div>
                                {orderDetails?.brandName}
                            </div>
                            <div>
                                {orderDetails?.firmName}
                            </div>


                            <div className={styles.officeAddress}>
                                {orderDetails?.officeAddress}
                            </div>
                            <div className={styles.gstDetailsWrapper}>
                                <div>GST Number</div>
                                <div>{orderDetails?.gstNumber}</div>
                            </div>
                        </div>
                        <div className={styles.rightColSystem}>
                            <div>
                                {orderDetails?.currentStatus}
                            </div>
                            <div>
                                {orderDetails?.placedOn}
                            </div>
                            <div className={styles.totalOrderValue}>
                                ₹{totalOrderValue}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.mainBodyWrapper}>
                    <div className={styles.leftWrapper}>
                        <div className={styles.itemDetailsTitle}>
                            <div>
                                Item Details - ({cartItems?.length})
                            </div>
                            <div className={styles.manageShippingBtn}>
                                <Link to={`/marketplace/bulk-order/${orderIdentifier}/manage-shipping`}>Manage Shipping</Link>
                            </div>
                        </div>
                        <div className={styles.itemsWrapper}>
                            {cartItems?.map((item, index) => (
                                <div key={index} className={styles.eachProductRow}>
                                    <div className={styles.sn}>
                                        {index + 1}.
                                    </div>
                                    <div className={styles.productTitle}>
                                        {item?.productTitle}
                                    </div>
                                    <div className={styles.priceWithGst}>
                                        ₹{item?.priceWithGst}
                                    </div>
                                    <div>
                                        x
                                    </div>
                                    <div className={styles.quantity}>
                                        {item?.quantity}
                                    </div>
                                    <div className={styles.totalPrice}>
                                        ₹{item?.totalPrice}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.rightWrapper}>
                        <div>
                            <div className={styles.updateCurrentStatusTxt}>
                                Update Current Status
                            </div>
                            <div className={styles.currentStatusDropper}>
                                <select
                                    onChange={(e) => setStatusToUpdate(e.target.value)}
                                >
                                    <option value="">Select Status</option>
                                    <option value={`Packaging Started`} >Packaging Started</option>
                                    <option value={`Partially Shipped`} >Partially Shipped</option>
                                    <option value={`All Items Shipped`} >All Items Shipped</option>
                                    <option value={`Partially Delivered`} >Partially Delivered</option>
                                    <option value={`All Items Delivered`} >All Items Delivered</option>
                                </select>
                            </div>
                        </div>

                        <div className={styles.invoiceActionWrapper}>
                            <div>
                                <button onClick={() => setIsEditingInvoice(true)} className={styles.updateInvoiceBtn} >Update Invoice</button>
                            </div>
                            <div>
                                <button onClick={() => setIsGeneratingInvoice(true)}  className={styles.generateInvoiceBtn} >Generate Invoice</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default OrderDetails