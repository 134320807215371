import React from 'react'
import styles from './EachSchemeOffer.module.css'

const EachSchemeOffer = (props) => {
    const { count, item } = props
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.sn}>
                {count}
            </div>
            <div className={styles.schemeBanner}>
                <div className={styles.schemeBannerContainer}>
                    <img src={item?.schemeBanner} />
                </div>
            </div>
            <div className={styles.schemeTitle}>
                {item?.schemeTitle}
            </div>
            <div className={styles.description}>
                {item?.description}
            </div>
            <div className={styles.startDate}>
                {item?.startDate}
            </div>
            <div className={styles.endDate}>
                {item?.endDate}
            </div>
        </div>
    )
}

export default EachSchemeOffer