export const numberToWords = (inputNumber) => {
    let numberString = inputNumber.toString().replace(/[,.]/g, ''); // Remove commas and dots

    if (isNaN(numberString)) {
        return 'Invalid number';
    }

    let number = Number(numberString);

    if (number === 0) {
        return 'zero';
    }

    const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    const tens = ['', 'ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    function convertLessThanOneThousand(num) {
        let current;
        if (num % 100 < 10) {
            current = ones[num % 100];
            num = Math.floor(num / 100);
        } else if (num % 100 < 20) {
            current = teens[num % 10];
            num = Math.floor(num / 100);
        } else {
            current = ones[num % 10];
            num = Math.floor(num / 10);
            current = tens[num % 10] + ' ' + current;
            num = Math.floor(num / 10);
        }
        if (num === 0) return current;
        return ones[num] + ' hundred' + (current === '' ? '' : ' ' + current);
    }

    let result = '';
    let chunk;
    let chunkIndex = 0;

    do {
        chunk = number % 1000;
        if (chunk !== 0) {
            const chunkStr = convertLessThanOneThousand(chunk);
            result = chunkStr + ['', ' thousand', ' million', ' billion', ' trillion', ' quadrillion', ' quintillion', ' sextillion'][chunkIndex] + (result === '' ? '' : ' ' + result);
        }
        number = Math.floor(number / 1000);
        chunkIndex++;
    } while (number > 0);

    return result;
}

// Example usage:
// console.log(numberToWords('3452')); // Output: "three thousand four hundred fifty two"
// console.log(numberToWords('1,234,567,890')); // Output: "one billion two hundred thirty four million five hundred sixty seven thousand eight hundred ninety"
// console.log(numberToWords('1.23456789e15')); // Output: "one quadrillion two hundred thirty four trillion five hundred sixty seven billion eight hundred ninety million"
// console.log(numberToWords('invalid')); // Output: "Invalid number"
// console.log(numberToWords('123456789012345678901234567890')); // Output: "one hundred twenty three octillion four hundred fifty six septillion seven hundred eighty nine sextillion twelve quintillion three hundred forty five quadrillion six hundred seventy eight trillion nine hundred one billion two hundred thirty four million five hundred sixty seven thousand eight hundred ninety"
