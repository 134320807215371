import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import MainHeader from '../../Components/Headers/MainHeader'
import EachItemShipping from '../../Components/ManageShipping/EachItemShipping'
import ManageShippingLocations from '../../Components/ManageShipping/ManageShippingLocations'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './ManageShipping.module.css'

const ManageShipping = () => {
    const params = useParams()
    const { orderIdentifier } = params

    const [cartItems, setCartItems] = useState([])
    const authCtx = useContext(AuthContext)
    const [isOpen, setIsOpen] = useState(false)
    const [activeItem, setActiveItem] = useState('')


    const getBulkOrderCartStats = async () => {
        const getBulkOrderCartStatsResponse = await fetch(BASE_URL_API + "/getBulkOrderCartStats",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    orderIdentifier: orderIdentifier
                })
            });

        if (!getBulkOrderCartStatsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getBulkOrderCartStatsRespo = await getBulkOrderCartStatsResponse.json()
            if (getBulkOrderCartStatsRespo.status === "success") {
                setCartItems(getBulkOrderCartStatsRespo?.response)
            } else {
                if (getBulkOrderCartStatsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getBulkOrderCartStatsRespo)
        }
    }

    const closeItemHandler = () => setIsOpen(false)

    useEffect(() => {
        getBulkOrderCartStats()
    }, [orderIdentifier])

    const activeItemHandler = (k) => {
        setActiveItem(k)
        setIsOpen(true)
    }

    return (
        <div>
            <MainHeader />
            <ManageShippingLocations 
                isOpen={isOpen} 
                activeItem={activeItem} 
                closeItemHandler={closeItemHandler} 
            />
            {/* <div>
                {orderIdentifier}
            </div> */}
            <div className={styles.headerWrapper}>
                <div className={styles.sn}>#</div>
                <div className={styles.productTitle}>Product Title</div>
                <div className={styles.quantity}>Qty.</div>
                <div className={styles.action}>Manage Shipping</div>
                <div className={styles.action}>Manage Vendor</div>
            </div>

            <div>
                {cartItems?.map((item, index) => (
                    <EachItemShipping 
                        item={item} 
                        count={index} 
                        activeItem={activeItem} 
                        activeItemHandler={activeItemHandler} 
                    />
                ))}
            </div>

        </div>
    )
}

export default ManageShipping