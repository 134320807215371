import React from 'react'
import { Link } from 'react-router-dom'
import styles from './EachClientRow.module.css'

const EachClientRow = (props) => {
    const { count, item } = props
    console.log(item)

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.sn}>
                {count+1}.
            </div>
            <div className={styles.brandName}>
                {item?.brandName}
            </div>
            <div className={styles.firmName}>
                <div>
                    {item?.firmName}
                </div>
                <div className={styles.officeAddress}>
                    {item?.officeAddress}
                </div>
            </div>
            <div className={styles.gst}>
                 {item?.gstNumber}
            </div>
            <div className={styles.cin}>
                {item?.cin}
            </div>
            <div className={styles.createdOn}>
                {item?.createdOn?.substring(0, 10)}
            </div>
            <div className={styles.manageAuth}>
                <Link to={`/clients/${item?.clientIdentifier}/auth`}>Manage Auth Credentials</Link>
            </div>
        </div>
    )
}

export default EachClientRow