import React, { useContext, useEffect, useState } from 'react'
import { useParams  } from 'react-router'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import { Link } from 'react-router-dom'
import MainHeader from '../../Components/Headers/MainHeader'
import EachProductRowForPlacingOrder from '../../Components/Marketplace/EachProductRowForPlacingOrder'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './CreateOrder.module.css'

const CreateOrder = () => {
    const authCtx = useContext(AuthContext)
    const [filtersArray, setFiltersArray] = useState([])
    const params = useParams()
    const orderIdentifier = params?.orderIdentifier

    const [selectedCategory, setSelectedCategory] = useState([])
    const [selectedBrand, setSelectedBrand] = useState([])
    const [selectedVendor, setSelectedVendor] = useState([])

    const [productData, setProductData] = useState([])
    const [cartStats, setCartStats] = useState([])

    const selectCategoryHandler = (k) => {
        if (selectedCategory?.some((item) => item?.ID === k.ID)) {
            setSelectedCategory(selectedCategory?.filter((itemFilter) => itemFilter.ID !== k.ID))
        } else {
            setSelectedCategory([...selectedCategory, k])
        }
    }
    const selectBrandHandler = (k) => {
        if (selectedBrand?.some((item) => item?.title === k.title)) {
            setSelectedBrand(selectedBrand?.filter((itemFilter) => itemFilter.title !== k.title))
        } else {
            setSelectedBrand([...selectedBrand, k])
        }
    }
    const selectVendorHandler = (k) => {
        if (selectedVendor?.some((item) => item?.vendorIdentifier === k.vendorIdentifier)) {
            setSelectedBrand(selectedBrand?.filter((itemFilter) => itemFilter.vendorIdentifier !== k.vendorIdentifier))
        } else {
            setSelectedBrand([...selectedBrand, k])
        }
    }

    const getEcomFilterParameters = async () => {
        const getEcomFilterParametersResponse = await fetch(BASE_URL_API+"/getEcomFilterParameters",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token
            })
        });

        if(!getEcomFilterParametersResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getEcomFilterParametersRespo = await getEcomFilterParametersResponse.json()
            if(getEcomFilterParametersRespo.status==="success") {
                setFiltersArray(getEcomFilterParametersRespo.response)
            } else {
                if(getEcomFilterParametersRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getEcomFilterParametersRespo)
        }
    }


    useEffect(() => {
        getEcomFilterParameters()
    }, [])

    const getEcomProducts = async () => {
        const getEcomProductsResponse = await fetch(BASE_URL_API+"/getEcomProductsForPlacingOrder",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                selectedBrand : selectedBrand,
                selectedCategory : selectedCategory,
                selectedVendor : selectedVendor
            })
        });

        if(!getEcomProductsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getEcomProductsRespo = await getEcomProductsResponse.json()
            if(getEcomProductsRespo.status ==="success") {
                setProductData(getEcomProductsRespo.response)
            } else {
                if(getEcomProductsRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setProductData([])
                }
            }
            console.log(getEcomProductsRespo)
        }
    }
    
    const getBulkOrderCartStats = async () => {
        const getBulkOrderCartStatsResponse = await fetch(BASE_URL_API+"/getBulkOrderCartStats",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                orderIdentifier : orderIdentifier
            })
        });

        if(!getBulkOrderCartStatsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getBulkOrderCartStatsRespo = await getBulkOrderCartStatsResponse.json()
            if(getBulkOrderCartStatsRespo.status==="success") {
                setCartStats(getBulkOrderCartStatsRespo?.response)
            } else {
                if(getBulkOrderCartStatsRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getBulkOrderCartStatsRespo)
        }
    }

    useEffect(() => {
        getEcomProducts()
    }, [selectedBrand, selectedCategory, selectedVendor])

    useEffect(() => {
        getBulkOrderCartStats()
    }, [])
    

    return (
        <div>
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.leftWrapper}>

                        <div>Create Order</div>
                        <div className={styles.eachFilterWrapper}>
                            <div className={styles.eachFilterHeader}>
                                <div>Categories</div>
                                <div>
                                    {selectedCategory?.length} / {filtersArray?.allCategory?.length}
                                </div>
                            </div>
                            {filtersArray?.allCategory?.map((item, index) => (
                                <div key={index} className={styles.eachCategory} onClick={() => selectCategoryHandler(item)}>
                                    <div className={styles.checkBox}>
                                        {selectedCategory?.some((itemA) => itemA?.ID === item?.ID) === true ?
                                            <MdCheckBox size={24} />
                                            :
                                            <MdCheckBoxOutlineBlank size={24} />
                                        }
                                    </div>
                                    <div>
                                        {item?.title}
                                    </div>
                                </div>
                            ))}
                        </div>
                        
                        <div className={styles.eachFilterWrapper}>
                            <div className={styles.eachFilterHeader}>
                                <div>Brands</div>
                                <div>
                                    {selectedBrand?.length} / {filtersArray?.allBrands?.length}
                                </div>
                            </div>
                            {filtersArray?.allBrands?.map((item, index) => (
                                <div key={index} className={styles.eachCategory} onClick={() => selectBrandHandler(item)}>
                                    <div className={styles.checkBox}>
                                        {selectedBrand?.some((itemA) => itemA?.title === item?.title) === true ?
                                            <MdCheckBox size={24} />
                                            :
                                            <MdCheckBoxOutlineBlank size={24} />
                                        }
                                    </div>
                                    <div>
                                        {item?.title}
                                    </div>
                                </div>
                            ))}
                        </div>
                        
                        <div className={styles.eachFilterWrapper}>
                            <div className={styles.eachFilterHeader}>
                                <div>Vendors</div>
                                <div>
                                    {selectedVendor?.length} / {filtersArray?.allVendors?.length}
                                </div>
                            </div>
                            {filtersArray?.allVendors?.map((item, index) => (
                                <div key={index} className={styles.eachCategory} onClick={() => selectBrandHandler(item)}>
                                    <div className={styles.checkBox}>
                                        {selectedBrand?.some((itemA) => itemA?.vendorIdentifier === item?.vendorIdentifier) === true ?
                                            <MdCheckBox size={24} />
                                            :
                                            <MdCheckBoxOutlineBlank size={24} />
                                        }
                                    </div>
                                    <div>
                                        {item?.brandName}
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>

                    <div className={styles.centerWrapper}>
                        <div className={styles.actionWrapper}>
                            <div className={styles.searchWrapper}>
                                <input placeholder="Search for products...." />
                            </div>
                            <div className={styles.goToCartBtn}>
                                <Link to={`/marketplace/cart/${orderIdentifier}`}>Go To Cart ({cartStats?.length})</Link>
                            </div>

                        </div>

                        <div className={styles.tableHeaderWrapper}>
                            <div className={styles.sn}>#</div>
                            <div className={styles.thumbnail}>Thumbnail</div>
                            <div className={styles.title}>Title</div>
                            <div className={styles.brand}>Brand</div>
                            <div className={styles.category}>Category</div>
                            <div className={styles.action}>Action</div>
                        </div>

                        <div className={styles.tableBodyWrapper}>
                            {productData?.map((item, index) => (
                                <EachProductRowForPlacingOrder 
                                    key={index} 
                                    item={item} 
                                    orderIdentifier={orderIdentifier} 
                                    count={index} 
                                    onSuccess={getBulkOrderCartStats}
                                    isAdded={cartStats?.some((itemCart) => itemCart?.productIdentifier === item?.id)}
                                />
                            ))}
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default CreateOrder