import React, { useContext } from 'react'
import styles from './Home.module.css'
import { Link } from 'react-router-dom'
import AuthContext from '../../Store/auth-context'
import MainHeader from '../../Components/Headers/MainHeader'

const Home = () => {
    const authCtx = useContext(AuthContext)
    return (
        <div className={styles.mainWrapper}>
            <MainHeader />
            <div className={styles.mainBodyWrapper}>


                <div className={styles.eachNavBlock}>
                    <div className={styles.eachNavBlockHeader}>
                        Manage Clients
                    </div>
                    <div className={styles.eachSubNavWrapper}>
                        <Link to={`/clients`} >
                            <div className={styles.eachNavOption}>
                                All Clients
                            </div>
                        </Link>
                        <Link to={`/clients/onboard`} >
                            <div className={styles.eachNavOption}>
                                Onboard New Clients
                            </div>
                        </Link>
                    </div>
                </div>

                <div className={styles.eachNavBlock}>
                    <div className={styles.eachNavBlockHeader}>
                        Manage Vendors
                    </div>
                    <div className={styles.eachSubNavWrapper}>
                        <Link to={`/vendors`} >
                            <div className={styles.eachNavOption}>
                                All Vendors
                            </div>
                        </Link>
                        <Link to={`/vendors/onboard`} >
                            <div className={styles.eachNavOption}>
                                Onboard New Vendors
                            </div>
                        </Link>
                    </div>
                </div>


                <div className={styles.eachNavBlock}>
                    <div className={styles.eachNavBlockHeader}>
                        QRs
                    </div>
                    <div className={styles.eachSubNavWrapper}>
                        <Link to={`/client-products`} >
                            <div className={styles.eachNavOption}>
                                Client Products
                            </div>
                        </Link>
                        <Link to={`/qrc/generate`} >
                            <div className={styles.eachNavOption}>
                                Generate QR Codes
                            </div>
                        </Link>
                    </div>
                </div>


                <div className={styles.eachNavBlock}>
                    <div className={styles.eachNavBlockHeader}>
                        Analytics
                    </div>
                    <div className={styles.eachSubNavWrapper}>
                        <Link to={`/analytics/summary`} >
                            <div className={styles.eachNavOption}>
                                Summary
                            </div>
                        </Link>
                        <Link to={`/analytics/users`} >
                            <div className={styles.eachNavOption}>
                                User Analytics
                            </div>
                        </Link>
                        <Link to={`/analytics/codes-scanned`} >
                            <div className={styles.eachNavOption}>
                                Scanned Codes Analytics
                            </div>
                        </Link>
                    </div>
                </div>


                <div className={styles.eachNavBlock}>
                    <div className={styles.eachNavBlockHeader}>
                        Marketplace
                    </div>
                    <div className={styles.eachSubNavWrapper}>
                        <Link to={`/marketplace/all-products`} >
                            <div className={styles.eachNavOption}>
                                View All Products
                            </div>
                        </Link>
                        <Link to={`/marketplace/add-products`} >
                            <div className={styles.eachNavOption}>
                                Add Products
                            </div>
                        </Link>
                        {/* <Link to={`/marketplace/edit-products`} >
                            <div className={styles.eachNavOption}>
                                Edit Products
                            </div>
                        </Link> */}
                        
                    </div>
                </div>

                <div className={styles.eachNavBlock}>
                    <div className={styles.eachNavBlockHeader}>
                        Manage Orders
                    </div>
                    <div className={styles.eachSubNavWrapper}>
                        <Link to={`/marketplace/create-order`} >
                            <div className={styles.eachNavOption}>
                                Create Order
                            </div>
                        </Link>
                        <Link to={`/marketplace/view-orders`} >
                            <div className={styles.eachNavOption}>
                                View Orders
                            </div>
                        </Link>
                        <Link to={`/marketplace/bulk-orders`} >
                            <div className={styles.eachNavOption}>
                                View Bulk Orders
                            </div>
                        </Link>
                    </div>
                </div>

                <div className={styles.eachNavBlock}>
                    <div className={styles.eachNavBlockHeader}>
                        Schemes & Offers
                    </div>
                    <div className={styles.eachSubNavWrapper}>
                        <Link to={`/schemes-and-offers`} >
                            <div className={styles.eachNavOption}>
                                Schemes & Offers
                            </div>
                        </Link>
                        <Link to={`/schemes-and-offers/create-a-new`} >
                            <div className={styles.eachNavOption}>
                                Create New Schemes & Offers
                            </div>
                        </Link>
                    </div>
                </div>


                <div className={styles.eachNavBlock}>
                    <div className={styles.eachNavBlockHeader}>
                        Customer Support & Ticketing
                    </div>
                    <div className={styles.eachSubNavWrapper}>
                        <Link to={`/support-tickets`} >
                            <div className={styles.eachNavOption}>
                                All Tickets
                            </div>
                        </Link>
                    </div>
                </div>

                <div className={styles.eachNavBlock}>
                    <div className={styles.eachNavBlockHeader}>
                        Users
                    </div>
                    <div className={styles.eachSubNavWrapper}>
                        <Link to={`/users`} >
                            <div className={styles.eachNavOption}>
                                All Users
                            </div>
                        </Link>
                        <Link to={`/users/create-new-user`} >
                            <div className={styles.eachNavOption}>
                                Create New User
                            </div>
                        </Link>
                        <Link to={`/users/bulk-upload`} >
                            <div className={styles.eachNavOption}>
                                Upload user in bulk
                            </div>
                        </Link>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default Home