import React from 'react'
import styles from './EachRowViewOrder.module.css'


const EachRowViewOrder = (props) => {
    const { item, count } = props
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.sn}>
                {count}
            </div>
            <div className={styles.orderId}>
                {item?.orderIdentifier}
            </div>
            <div className={styles.item}>
                {item?.itemTitle}
            </div>
            <div className={styles.pointsUsed}>
                {item?.pointsUsed}
            </div>
            <div className={styles.phone}>
                {item?.receiverPhone}
            </div>
            <div className={styles.address}>
                {item?.deliveryAddress}
            </div>
            <div className={styles.pincode}>
                {item?.deliveryPincode}
            </div>
            <div className={styles.date}>
                {item?.placedOn}
            </div>
            <div className={styles.currentStatus}>
                {item?.currentStatus}
            </div>
        </div>
    )
}

export default EachRowViewOrder