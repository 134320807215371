import React from 'react'
import styles from './EachUserRow.module.css'

const EachUserRow = (props) => {
    const { count, item } = props
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.sn}>
                {count}
            </div>
            <div className={styles.userId}>
                {item?.userIdentifier}
            </div>
            <div className={styles.phoneNumber}>
                {item?.phoneNumber}
            </div>
            <div className={styles.firstName}>
                {item?.firstName}
            </div>
            <div className={styles.lastName}>
                {item?.lastName}
            </div>
            <div className={styles.gender}>
                {item?.gender}
            </div>
            <div className={styles.businessName}>
                {item?.businessName}
            </div>
            <div className={styles.designation}>
                {item?.designation}
            </div>
            <div className={styles.state}>
                {item?.state}
            </div>
            <div className={styles.referralCode}>
                {item?.referralCode}
            </div>
            <div className={styles.addedOn}>
                {item?.createdOn}
            </div>
        </div>
    )
}

export default EachUserRow