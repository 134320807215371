import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import ClientAuthEachRow from '../../Components/Clients/ClientAuthEachRow'
import MainHeader from '../../Components/Headers/MainHeader'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './ClientAuth.module.css'


const ClientAuth = () => {
    const authCtx = useContext(AuthContext)
    const { clientIdentifier } = useParams()

    const [allClientAuth, setAllClientAuth] = useState([])
    const [isLoadingAuthRecords, setIsLoadingAuthRecords] = useState(false)
    const [isGeneratingNewCredentials, setIsGeneratingNewCredentials] = useState(false)

    const getAllClientAuth = async () => {
        setIsLoadingAuthRecords(true)
        const getAllClientAuthResponse = await fetch(BASE_URL_API + "/getAllClientAuth",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    clientIdentifier: clientIdentifier
                })
            });

        if (!getAllClientAuthResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllClientAuthRespo = await getAllClientAuthResponse.json()
            if (getAllClientAuthRespo.status === "success") {
                setAllClientAuth(getAllClientAuthRespo.response)
            } else {
                if (getAllClientAuthRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getAllClientAuthRespo)
        }
        setIsLoadingAuthRecords(false)
    }


    const createNewClientAuth = async () => {
        setIsGeneratingNewCredentials(true)
        const createNewClientAuthResponse = await fetch(BASE_URL_API + "/createNewClientAuth",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    clientIdentifier: clientIdentifier
                })
            });

        if (!createNewClientAuthResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const createNewClientAuthRespo = await createNewClientAuthResponse.json()
            if (createNewClientAuthRespo.status === "success") {
                getAllClientAuth()
            } else {
                if (createNewClientAuthRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }

        }
        setIsGeneratingNewCredentials(false)

    }


    useEffect(() => {
        getAllClientAuth()
    }, [])


    return (
        <div>
            <MainHeader />
            <div>
                <div className={styles.titleWrapper}>
                    <div>Manage your client's login access</div>
                    <div>
                        {isGeneratingNewCredentials === false ?
                            <button onClick={() => createNewClientAuth()}>Create New Auth</button>
                            :
                            <button >Creating New Credentials...</button>
                        }
                    </div>
                </div>
                <div>
                    <div className={styles.tableHeader}>
                        <div className={styles.sn}>#</div>
                        <div className={styles.username}>Username</div>
                        <div className={styles.createdOn}>Created On</div>
                        <div className={styles.activeSince}>Login Status</div>
                        <div className={styles.action}>Action</div>
                    </div>
                    <div>
                        {isLoadingAuthRecords === true ?
                            <div>
                                Loading auth records..
                            </div>
                            :
                            <div>
                                {allClientAuth?.map((item, index) => (
                                    <ClientAuthEachRow key={index} getAllClientAuth={getAllClientAuth} item={item} count={index + 1} />
                                ))}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientAuth