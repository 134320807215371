import React, { useContext, useEffect, useState, useRef } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import EachCodeRow from './EachCodeRow'
import styles from './MapQRCodes.module.css'
import QRCodeGenerator from './QRCodeGenerator'

import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import QRCode from 'react-qr-code';
import JSZip from 'jszip';

import html2canvas from 'html2canvas';
import QRCodeRenderer from './QRCodeRenderer'



const MapQRCodes = () => {
    const authCtx = useContext(AuthContext)

    const [codesData, setCodesData] = useState([])
    const [selectedCodes, setSelectedCodes] = useState([])
    const [fetchingFilter, setFetchingFilter] = useState('ALL'); // UNSCANNED
    const [isGettingQRs, setIsGettingQRs] = useState(false)
    const selectionHandler = (k) => {
        if (selectedCodes.includes(k)) {
            setSelectedCodes(
                selectedCodes.filter((item) => item !== k)
            )
        } else {
            setSelectedCodes([...selectedCodes, k])
        }
    }

    const getGeneratedQRCodes = async () => {
        setIsGettingQRs(true)
        const getGeneratedQRCodesResponse = await fetch(BASE_URL_API + "/getGeneratedQRCodes",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    fetchingFilter: fetchingFilter
                })
            });

        if (!getGeneratedQRCodesResponse.ok) {
            console.log("Something went wrong : Server Error ")
        } else {
            const getGeneratedQRCodesRespo = await getGeneratedQRCodesResponse.json()
            if (getGeneratedQRCodesRespo.status === "success") {
                setCodesData(getGeneratedQRCodesRespo.response)
            } else {
                if (getGeneratedQRCodesRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setCodesData([])
                }
            }

        }
        setIsGettingQRs(false)
    }

    useEffect(() => {
        getGeneratedQRCodes()
    }, [fetchingFilter])


    const multipleSelectionHandler = (k) => {
        // make current selection zero.. 
        let tempSelected = selectedCodes
        tempSelected = []
        let copiedCodes = codesData.slice(0, k)
        setSelectedCodes(copiedCodes.map(item => item?.codeIdentifier))
    }


    const downloadQRCodeInBulk = () => {
        selectedCodes?.map((item, index) => (
            domtoimage.toBlob(document.getElementById(`${item}-qr-code`))
                .then(function (blob) {
                    saveAs(blob, `${item}.png`);
                })
        ))
    };



    // const downloadBulkQRCodeInZip = () => {
    //     const zip = new JSZip();
    //     const promises = [];

    //     selectedCodes?.forEach((item, index) => {
    //         const qrCodeDiv = document.createElement('div');
    //         qrCodeDiv.innerHTML = `<QRCode size={64} value="${item}" />`;

    //         // Convert QR code to image using dom-to-image
    //         promises.push(
    //             domtoimage.toBlob(qrCodeDiv)
    //                 .then(function (blob) {
    //                     // Add blob to zip file
    //                     zip.file(`${item}-qr-code.png`, blob);
    //                 })
    //         );
    //     });

    //     // Wait for all promises to resolve
    //     Promise.all(promises).then(() => {
    //         // Generate zip file and initiate download
    //         zip.generateAsync({ type: 'blob' }).then(function (blob) {
    //             saveAs(blob, 'bulk_qrcodes.zip');
    //         });
    //     });
    // };



    // const downloadBulkQRCodeInZip = () => {
    //     const zip = new JSZip();
    //     const promises = [];

    //     selectedCodes?.forEach((item, index) => {
    //         // Create a ref for the QRCode component
    //         const qrCodeRef = useRef();

    //         // Push the promise to the array
    //         promises.push(
    //             new Promise((resolve) => {
    //                 // Wait for the QRCode component to be rendered
    //                 const intervalId = setInterval(() => {
    //                     if (qrCodeRef.current) {
    //                         clearInterval(intervalId);
    //                         // Capture the QRCode component using html2canvas
    //                         html2canvas(qrCodeRef.current, { backgroundColor: 'white' })
    //                             .then((canvas) => {
    //                                 // Convert canvas to blob
    //                                 canvas.toBlob((blob) => {
    //                                     // Add blob to zip file
    //                                     zip.file(`${item}-qr-code.png`, blob);
    //                                     resolve();
    //                                 }, 'image/png');
    //                             });
    //                     }
    //                 }, 100);
    //             })
    //         );
    //     });

    //     // Wait for all promises to resolve
    //     Promise.all(promises).then(() => {
    //         // Generate zip file and initiate download
    //         zip.generateAsync({ type: 'blob' }).then(function (blob) {
    //             saveAs(blob, 'bulk_qrcodes.zip');
    //         });
    //     });
    // };



    const downloadBulkQRCodeInZip = () => {
        const zip = new JSZip();
        const promises = [];


        selectedCodes?.forEach((item, index) => {
            console.log("jhghg", item)
            // Define a promise for each QR code generation
            const promise = new Promise((resolve) => {
                // Define onQRCodeGenerated function
                const onQRCodeGenerated = (blob) => {
                    console.log(`QR code ${item} generated. Blob size: ${blob.size}`);
                    zip.file(`${item}-qr-code.png`, blob);
                    resolve(); // Resolve the promise when QR code is generated
                };

                // Render the QRCodeRenderer component
                <QRCodeRenderer key={index} value={item} onQRCodeGenerated={onQRCodeGenerated} />;
            });

            // Push the promise to the array
            promises.push(promise);

            console.log("Promise status : ", promise)
        });

        // Wait for all promises to resolve
        Promise.all(promises).then(() => {
            console.log("All QR codes generated. Total files to be added to zip:", Object.keys(zip.files).length);
            // Generate zip file and initiate download
            zip.generateAsync({ type: 'blob' }).then(function (blob) {
                console.log("Zip file generated. Blob size:", blob.size);
                saveAs(blob, 'bulk_qrcodes.zip');
            });
        });
    };





    return (
        <div>
            <div>
                <div className={styles.titleWrapper}>
                    <div className={styles.qrTagline}>
                        Map QR Codes with products
                    </div>
                    <div className={styles.qrTaglineSubTxt}>
                        To make QR codes scannable, you must map it with a product
                    </div>
                    <div>
                        <button onClick={() => setFetchingFilter("ALL")} >Fetch All Codes</button>
                        <button onClick={() => setFetchingFilter("UNSCANNED")} >Fetch Unscanned Codes</button>
                    </div>
                </div>
                <div className={styles.actionHeaderWrapper}>
                    <div className={styles.selectBulkOptionsWrapper}>
                        <button onClick={() => multipleSelectionHandler(10)}>Select 10 Codes</button>
                        <button onClick={() => multipleSelectionHandler(20)}>Select 20 Codes</button>
                        <button onClick={() => multipleSelectionHandler(50)}>Select 50 Codes</button>
                        <button onClick={() => multipleSelectionHandler(100)}>Select 100 Codes</button>
                    </div>
                    <div className={styles.downloadActionWrapper}>
                        <div>
                            {selectedCodes.length} / {codesData.length}
                        </div>
                        <div className={styles.downloadBtnWrapper}>
                            <button onClick={() => downloadQRCodeInBulk()} >Download</button>
                            {/* <button onClick={() => downloadBulkQRCodeInZip()} >Download In Zip</button> */}
                        </div>
                    </div>
                </div>
            </div>
            {isGettingQRs === true ?
                <div className={styles.naWrapper}>
                    Hold On!
                    Collecting QRs Data...
                </div>
                :
                <div>
                    {codesData?.length > 0 ?
                        <div>
                            <div className={styles.tableHeader}>
                                <div className={styles.sn}>#</div>
                                <div className={styles.sn}>-</div>
                                <div className={styles.codeWrapper}>View & Download</div>
                                <div className={styles.mappedWithProduct}>Map With Product</div>
                                <div className={styles.hasScanned}>Has Scanned?</div>
                                <div className={styles.scannedOn}>Scanned On</div>
                                <div className={styles.actionWrapper}>Action</div>
                            </div>
                            <div className={styles.tableWrapper}>
                                {codesData?.map((item, index) => (
                                    <EachCodeRow key={index} isSelected={selectedCodes.includes(item?.codeIdentifier)} selectionHandler={selectionHandler} count={index} item={item} />
                                ))}
                            </div>
                        </div>
                        :
                        <div className={styles.naWrapper}>
                            No QR code available!
                        </div>
                    }
                </div>
            }


        </div>
    )
}

export default MapQRCodes