import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './EachCartItemRow.module.css'

const EachCartItemRow = (props) => {
    const { item, count, onItemDeletion, getBillingSummary } = props
    const authCtx = useContext(AuthContext)
    const [basePrice, setBasePrice] = useState(item?.basePrice)
    const [cgst, setCgst] = useState(9)
    const [sgst, setSgst] = useState(9)
    const [igst, setIgst] = useState(18)
    const [mrp, setMrp] = useState('')
    const [quantity, setQuantity] = useState(item?.quantity !== '' ? item?.quantity : 1)
    const [finalPrice, setFinalPrice] = useState('')
    const [changesOccured, setChangesOccured] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isRemoving, setIsRemoving] = useState(false)


    const calculateMrp = () => {
        // Convert inputs to numbers and handle edge cases where inputs are not valid numbers
        let parsedBasePrice = parseFloat(basePrice);
        let parsedCgst = parseFloat(cgst);
        let parsedIgst = parseFloat(igst);

        // Check if parsedBasePrice is a valid number
        if (isNaN(parsedBasePrice)) {
            setMrp("Invalid base price");
            return;
        }

        // If IGST is not provided or invalid, use CGST
        if (igst === '' || isNaN(parsedIgst)) {
            if (isNaN(parsedCgst)) {
                setMrp("Invalid CGST");
                return;
            }
            let mrp = parsedBasePrice * (1 + parsedCgst / 100);
            mrp += parsedBasePrice * (parsedCgst / 100);
            setMrp(mrp.toFixed(2)); // Fix to 2 decimal places for better readability
        } else {
            // If IGST is provided and valid
            let mrp = parsedBasePrice * (1 + parsedIgst / 100);
            setMrp(mrp.toFixed(2)); // Fix to 2 decimal places for better readability
        }
    };


    const calculateTotalPrice = () => {
        if (isNaN(mrp)) {
            setFinalPrice("Invalid Mrp")
            return
        } else {
            if (isNaN(quantity)) {
                setFinalPrice("Invalid Quantity")
                return
            }
            setFinalPrice(parseFloat(parseFloat(mrp) * parseInt(quantity)).toFixed(2))
        }
    }



    useEffect(() => {
        calculateMrp()
    }, [basePrice, cgst, sgst, igst])

    useEffect(() => {
        calculateTotalPrice()
    }, [quantity, mrp])

    useEffect(() => {
        setChangesOccured(true)
    }, [mrp, quantity])


    const updateCartItemParamsHandler = async () => {
        setIsUpdating(true)
        const updateCartItemParamsResponse = await fetch(BASE_URL_API + "/updateCartItemParams",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    cartItemIdentifier: item?.cartItemIdentifier,
                    basePrice: basePrice,
                    cgst: cgst,
                    sgst: sgst,
                    igst: igst,
                    priceWithGst: mrp,
                    quantity: quantity,
                    totalPrice: finalPrice
                })
            });

        if (!updateCartItemParamsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateCartItemParamsRespo = await updateCartItemParamsResponse.json()
            if (updateCartItemParamsRespo.status === "success") {
                getBillingSummary()
            } else {
                if (updateCartItemParamsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }

            console.log(updateCartItemParamsRespo)
        }
        setChangesOccured(false)
        setIsUpdating(false)

    }

    const removeCartItem = async () => {
        setIsRemoving(true)
        const removeCartItemResponse = await fetch(BASE_URL_API + "/removeCartItem",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    cartItemIdentifier: item?.cartItemIdentifier
                })
            });

        if (!removeCartItemResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const removeCartItemRespo = await removeCartItemResponse.json()
            if (removeCartItemRespo.status === "success") {
                getBillingSummary()
                onItemDeletion()
            } else {
                if (removeCartItemRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(removeCartItemRespo)
        }
        setIsRemoving(false)
    }


    return (
        <div className={styles.rowWrapper}>
            <div className={styles.sn}>
                {count + 1}
            </div>
            <div className={styles.title}>
                {item?.productTitle}
                <div className={styles.productBrand}>{item?.productBrand}</div>
            </div>
            <div className={styles.price}>
                <input placeholder="Enter base price" value={basePrice} onChange={(e) => setBasePrice(e.target.value)} />
            </div>
            <div className={styles.gst}>
                <input value={cgst} onChange={(e) => setCgst(e.target.value)} />
            </div>
            <div className={styles.gst}>
                <input value={sgst} onChange={(e) => setSgst(e.target.value)} />
            </div>
            <div className={styles.gst}>
                <input value={igst} onChange={(e) => setIgst(e.target.value)} />
            </div>
            <div className={styles.price}>
                {mrp}
            </div>
            <div className={styles.quantity}>
                <input value={quantity} type="number" onChange={(e) => setQuantity(e.target.value)} />
            </div>
            <div className={styles.finalPrice}>
                {finalPrice}
            </div>
            <div className={styles.action}>
                {changesOccured === true ?
                    isUpdating === true ?
                        <button>Updating...</button>
                        :
                        <button onClick={() => updateCartItemParamsHandler()} >Update</button>
                    :
                    <button>Updated</button>
                }
                {isRemoving === true ?
                    <button>Removing...</button>
                    :
                    <button onClick={() => removeCartItem()}>Remove</button>
                }
            </div>
        </div>
    )
}

export default EachCartItemRow