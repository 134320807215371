import React from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import styles from './EditProducts.module.css'

const EditProducts = () => {
    return (
        <div>
            <MainHeader />
            EditProducts
        </div>
    )
}

export default EditProducts