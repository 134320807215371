import React, { useContext, useEffect, useState } from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import EachRowViewOrder from '../../Components/Marketplace/EachRowViewOrder'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './ViewOrders.module.css'

const ViewOrders = () => {
    const authCtx = useContext(AuthContext)
    const [ordersData, setOrdersData] = useState([])

    const getPlacedOrders = async () => {
        const getPlacedOrdersResponse = await fetch(BASE_URL_API+"/getPlacedOrders",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token
            })
        });

        if(!getPlacedOrdersResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getPlacedOrdersRespo = await getPlacedOrdersResponse.json()
            if(getPlacedOrdersRespo.status==="success") {
                setOrdersData(getPlacedOrdersRespo.response)
            } else {
                if(getPlacedOrdersRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setOrdersData([])
                }
            }
            console.log(getPlacedOrdersRespo)
        }
    }

    useEffect(() => {
        getPlacedOrders()
    }, [])
    


    return (
        <div>
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.mainHeader}>
                    View All Orders
                </div>

                <div className={styles.tableWrapper}>
                    <div className={styles.tableHeader}>
                        <div className={styles.sn}>
                            #
                        </div>
                        <div className={styles.orderId}>
                            Order Id.
                        </div>
                        <div className={styles.item}>
                            Item
                        </div>
                        <div className={styles.pointsUsed}>
                            Points Used
                        </div>
                        <div className={styles.phone}>
                            Phone
                        </div>
                        <div className={styles.address}>
                            Delivery Address
                        </div>
                        <div className={styles.pincode}>
                            Pincode
                        </div>
                        <div className={styles.date}>
                            Placed On
                        </div>
                        <div className={styles.currentStatus}>
                            Current Status
                        </div>
                    </div>
                    <div className={styles.tableBody}>
                        {ordersData.map((item, index) => (
                            <EachRowViewOrder count={index+1} item={item} key={index} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewOrders