import React, { useContext, useState } from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './AddProducts.module.css'

const AddProducts = () => {
    const authCtx = useContext(AuthContext)

    const [title, setTitle] = useState()
    const [description, setDescription] = useState()
    const [pointsRequired, setPointsRequired] = useState()
    const [stock, setStock] = useState()
    const [brand, setBrand] = useState()
    const [category, setCategory] = useState()
    const [thumbnail, setThumbnail] = useState()
    const [mrp, setMrp] = useState(0)
    const [isUpdating, setIsUpdating] = useState(false)


    const clearFormHandler = () => {
        setTitle('')
        setDescription('')
        setPointsRequired('')
        setStock('')
        setBrand('')
        setCategory('')
        setThumbnail('')
        setMrp(0)
    }

    const addProductHandler = async () => {
        setIsUpdating(true)
        const addEcomProductResponse = await fetch(BASE_URL_API + "/addEcomProduct",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    title: title,
                    description: description,
                    pointsRequired: pointsRequired,
                    stock: stock,
                    brand: brand,
                    category: category,
                    thumbnail: thumbnail,
                    mrp : mrp
                })
            });

        if (!addEcomProductResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const addEcomProductRespo = await addEcomProductResponse.json()
            if (addEcomProductRespo.status === "success") {
                clearFormHandler()
            } else {
                if (addEcomProductRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(addEcomProductRespo)
        }
        setIsUpdating(false)

    }


    return (
        <div>
            <MainHeader />
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.pageTitle}>
                        Add Item
                    </div>
                    <div className={styles.editingFormWrapper}>
                        <div className={styles.leftFormWrapper}>
                            <div className={styles.title}>
                                <div className={styles.titleCaption}>Product Title</div>
                                <input placeholder="title" value={title} onChange={(e) => setTitle(e.target.value)} />
                            </div>
                            <div className={styles.description}>
                                <div className={styles.titleCaption}>Product Description</div>
                                <textarea value={description} onChange={(e) => setDescription(e.target.value)} >

                                </textarea>
                            </div>
                            <div className={styles.twoInRow}>
                                <div className={styles.pointsRequired}>
                                    <div className={styles.titleCaption}>Points Required</div>
                                    <input placeholder="points" type="number" value={pointsRequired} onChange={(e) => setPointsRequired(e.target.value)} />
                                </div>
                                <div className={styles.stock}>
                                    <div className={styles.titleCaption}>Stock</div>
                                    <input placeholder="stock" value={stock} type="number" onChange={(e) => setStock(e.target.value)} />
                                </div>
                            </div>

                        </div>
                        <div className={styles.rightFormWrapper}>
                            <div className={styles.category}>
                                <div className={styles.titleCaption}>Category</div>
                                <input placeholder="category" value={category} onChange={(e) => setCategory(e.target.value)} />
                            </div>

                            <div className={styles.thumbnail}>
                                <div className={styles.titleCaption}>Thumbnail</div>
                                <input placeholder="thumbnail" value={thumbnail} onChange={(e) => setThumbnail(e.target.value)} />
                            </div>
                            <div className={styles.twoInRow}>
                                <div className={styles.pointsRequired}>
                                    <div className={styles.titleCaption}>MRP</div>
                                    <input placeholder="mrp" value={mrp} type="number" onChange={(e) => setMrp(e.target.value)} />
                                </div>
                                <div className={styles.stock}>
                                    <div className={styles.titleCaption}>Brand</div>
                                    <input placeholder="Brand Name" value={brand} onChange={(e) => setBrand(e.target.value)} />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={styles.actionWrapper}>
                        {isUpdating === true ?
                            <div>Saving... </div>
                            :
                            <div className={styles.saveBtn}>
                                <button onClick={() => addProductHandler()}>Save</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddProducts