import React from 'react'
import styles from './ManageShippingLocations.module.css'

const ManageShippingLocations = (props) => {
    const { isOpen, activeItem, closeItemHandler } = props


    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.closeBtn}>
                        <button onClick={() => closeItemHandler()} >Close</button>
                    </div>
                    <div className={styles.locationsWrapper}>
                        <div className={styles.mainHeader}>
                            Manage Shipping Locations for {activeItem?.productTitle} ({activeItem?.quantity})
                        </div>
                        <div>
                        </div>
                        <div>
                            
                        </div>

                        <div className={styles.eachLocationWrapper}>
                            <div className={styles.elUpper}>
                                <div className={styles.eachFieldWrapper}>
                                    <div className={styles.eachFieldTitle}>
                                        Receiver Name
                                    </div>
                                    <div className={styles.fieldWrapper}>
                                        <input placeholder="Full name..." />
                                    </div>
                                </div>
                                <div className={styles.eachFieldWrapper}>
                                    <div className={styles.eachFieldTitle}>
                                        Phone Number
                                    </div>
                                    <div className={styles.fieldWrapper}>
                                        <input placeholder="10 digit phone number" />
                                    </div>
                                </div>
                                <div className={styles.eachFieldWrapper}>
                                    <div className={styles.eachFieldTitle}>
                                        Pincode
                                    </div>
                                    <div className={styles.fieldWrapper}>
                                        <input placeholder="pincode" />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.fullAddressWrapper}>
                                <div className={styles.eachFieldTitle}>
                                    Full Address
                                </div>
                                <div className={styles.fieldWrapper}>
                                    <textarea></textarea>
                                </div>
                            </div>

                            <div className={styles.quantUpdateWrapper}>
                                <div>
                                    <div className={styles.eachFieldTitle}>
                                        Quantity
                                    </div>
                                    <div className={styles.fieldWrapper}>
                                        <select>
                                            {[...Array(parseInt(activeItem?.quantity))].map((_, index) => (
                                                <option key={index}>{index + 1}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className={styles.updateBtnWrapper}>
                                    <button>Update</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default ManageShippingLocations