import React, { useContext, useEffect, useState } from 'react'
import EachClientRow from '../../Components/Clients/EachClientRow'
import MainHeader from '../../Components/Headers/MainHeader'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './AllClients.module.css'

const AllClients = () => {
    const authCtx = useContext(AuthContext)
    const [allClients, setAllClients] = useState([])


    const getAllClients = async () => {
        const getAllClientsResponse = await fetch(BASE_URL_API+"/getAllClients",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token
            })
        });

        if(!getAllClientsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllClientsRespo = await getAllClientsResponse.json();
            if(getAllClientsRespo.status==='success') {
                setAllClients(getAllClientsRespo.response)
            } else {
                if(getAllClientsRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllClients([])
                }
            }
        }
    }

    useEffect(() => {
        getAllClients()
    }, [])
    


    return (
        <div>
            <MainHeader />
            <div className={styles.mainContentWrapper}>
                <div className={styles.filtersWrapper}>
                    <div>
                        All Clients 
                    </div>
                    <div>
                        <select>
                            <option>All</option>
                        </select>
                    </div>
                </div>
                <div>
                    <div className={styles.tableHeader}>
                        <div className={styles.sn}>#</div>
                        <div className={styles.brandName}>Brand Name</div>
                        <div className={styles.firmName}>Firm Name</div>
                        <div className={styles.gst}>GST</div>
                        <div className={styles.cin}>State</div>
                        <div className={styles.createdOn}>Onboarded On</div>
                        <div className={styles.manageAuth}>Onboarded On</div>
                    </div>
                    <div className={styles.tableBody}>
                        {allClients?.map((item, index) => (
                            <EachClientRow key={index} count={index} item={item}  />
                        ))}
                        <div className={styles.thatsAll}>That's all</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllClients