import React from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import styles from './AnalyticsCodeScanned.module.css'

const AnalyticsCodeScanned = () => {
    return (
        <div>
            <MainHeader />
            AnalyticsCodeScanned
        </div>
    )
}

export default AnalyticsCodeScanned