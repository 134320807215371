import React, { useContext, useEffect, useState } from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import EachUserRow from '../../Components/Users/EachUserRow'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './Users.module.css'

const Users = () => {
    const authCtx = useContext(AuthContext)

    const [allUsers, setAllUsers] = useState([])

    const getAllUsers = async () => {
        const getAllUsersResponse = await fetch(BASE_URL_API + "/getAllUsers",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllUsersResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllUsersRespo = await getAllUsersResponse.json()
            if (getAllUsersRespo.status === "success") {
                setAllUsers(getAllUsersRespo.response)
            } else {
                if (getAllUsersRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllUsers([])
                }
            }
            console.log(getAllUsersRespo)
        }
    }

    useEffect(() => {
        getAllUsers()
    }, [])


    return (
        <div>
            <MainHeader />
            <div className={styles.bodyWrapper}>
                <div className={styles.filtersWrapper}>
                    <div>
                        <button>All Users</button>
                    </div>
                    <div>

                    </div>
                </div>
                <div className={styles.tableWrapper}>
                    <div className={styles.tableHeader}>
                        <div className={styles.sn}>
                            #
                        </div>
                        <div className={styles.userId}>
                            User Id
                        </div>
                        <div className={styles.phoneNumber}>
                            Phone
                        </div>
                        <div className={styles.firstName}>
                            First Name
                        </div>
                        <div className={styles.lastName}>
                            Last Name
                        </div>
                        <div className={styles.gender}>
                            Gender
                        </div>
                        <div className={styles.businessName}>
                            Business Name
                        </div>
                        <div className={styles.designation}>
                            Designation
                        </div>
                        <div className={styles.state}>
                            State
                        </div>
                        <div className={styles.referralCode}>
                            Referral Code
                        </div>
                        <div className={styles.addedOn}>
                            Added On
                        </div>

                    </div>
                    <div className={styles.tableBody}>

                        {allUsers?.map((item, index) => (
                            <EachUserRow count={index + 1} key={index} item={item} />
                        ))}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Users