import React, { useContext, useEffect, useState } from 'react'
import styles from './Cart.module.css'
import MainHeader from '../../Components/Headers/MainHeader'
import { useNavigate, useParams } from 'react-router'
import EachCartItemRow from '../../Components/Marketplace/EachCartItemRow'
import AuthContext from '../../Store/auth-context'
import { BASE_URL_API } from '../../References/urls'

const Cart = () => {
    const navigate = useNavigate()
    const { orderIdentifier } = useParams()
    const authCtx = useContext(AuthContext)
    const [cartItems, setCartItems] = useState([])
    const [isCollectingBillingSummary, setIsCollectingBillingSummary] = useState(false)
    const [billingSummary, setBillingSummary] = useState([])
    const [billingDetails, setBillingDetails] = useState('')
    const [clientsData, setClientsData] = useState([])
    const [selectedClient, setSelectedClient] = useState(null)
    const [isLoading, setIsLoading] = useState(false)


    const getBulkOrderCartStats = async () => {
        const getBulkOrderCartStatsResponse = await fetch(BASE_URL_API + "/getBulkOrderCartStats",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    orderIdentifier: orderIdentifier
                })
            });

        if (!getBulkOrderCartStatsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getBulkOrderCartStatsRespo = await getBulkOrderCartStatsResponse.json()
            if (getBulkOrderCartStatsRespo.status === "success") {
                setCartItems(getBulkOrderCartStatsRespo?.response)
            } else {
                if (getBulkOrderCartStatsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getBulkOrderCartStatsRespo)
        }
    }


    useEffect(() => {
        getBulkOrderCartStats()
    }, [orderIdentifier])

    const getBillingSummary = async () => {
        setIsCollectingBillingSummary(true)
        const getBillingSummaryResponse = await fetch(BASE_URL_API + "/getBillingSummary",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    orderIdentifier: orderIdentifier
                })
            });

        if (!getBillingSummaryResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getBillingSummaryRespo = await getBillingSummaryResponse.json()
            if (getBillingSummaryRespo.status === "success") {
                setBillingSummary(getBillingSummaryRespo.response)
            } else {
                if (getBillingSummaryRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setBillingSummary(null)
                }
            }
        }
        setIsCollectingBillingSummary(false)
    }

    useEffect(() => {
        getBillingSummary()
    }, [orderIdentifier])

    const calculateBillingSummary = () => {
        let grandTotalPrice = 0;
        let grandTotalGst = 0;
        let grandTotalWithGst = 0;
        let totalItems = 0;

        for (let item of billingSummary) {
            let basePrice = parseFloat(item.basePrice);
            let priceWithGst = parseFloat(item.priceWithGst);
            let quantity = parseInt(item.quantity);
            let totalPrice = parseFloat(item.totalPrice);

            if (isNaN(basePrice) || isNaN(priceWithGst) || isNaN(quantity) || isNaN(totalPrice)) {
                return "Invalid values";
            }

            grandTotalPrice += basePrice * quantity;
            grandTotalGst += priceWithGst * quantity;
            grandTotalWithGst += totalPrice;
            totalItems += quantity
        }

        grandTotalGst = grandTotalGst - grandTotalPrice

        return {
            grandTotalPrice: grandTotalPrice.toFixed(2),
            grandTotalGst: grandTotalGst.toFixed(2),
            grandTotalWithGst: grandTotalWithGst.toFixed(2),
            totalItems: totalItems
        };
    }

    useEffect(() => {
        setBillingDetails(calculateBillingSummary())
    }, [billingSummary])


    const getAllClients = async () => {
        const getAllClientsResponse = await fetch(BASE_URL_API + "/getAllClients",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllClientsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllClientsRespo = await getAllClientsResponse.json()
            if (getAllClientsRespo.status === "success") {
                setClientsData(getAllClientsRespo.response)
            } else {
                if (getAllClientsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setClientsData([])
                }
            }
        }
    }

    useEffect(() => {
        getAllClients()
    }, [orderIdentifier])

    const placeBulkOrder = async () => {
        setIsLoading(true)
        const placeBulkOrderResponse = await fetch(BASE_URL_API + "/placeBulkOrder",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    orderIdentifier: orderIdentifier,
                    clientIdentifier: JSON.parse(selectedClient)?.clientIdentifier
                })
            });

        if (!placeBulkOrderResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const placeBulkOrderRespo = await placeBulkOrderResponse.json()
            if (placeBulkOrderRespo.status === "success") {
                navigate(`/marketplace/bulk-orders`)
            } else {
                if (placeBulkOrderRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }

            console.log(placeBulkOrderRespo)
        }
        setIsLoading(false)
    }



    return (
        <div>
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.leftWrapper}>
                        <div className={styles.mainTop}>
                            Items in your cart!
                        </div>
                        <div className={styles.headerWrapper}>
                            <div className={styles.sn}>
                                #
                            </div>
                            <div className={styles.title}>
                                Title
                            </div>
                            <div className={styles.price}>
                                Price
                            </div>
                            <div className={styles.gst}>
                                CGST (%)
                            </div>
                            <div className={styles.gst}>
                                SGST (%)
                            </div>
                            <div className={styles.gst}>
                                IGST (%)
                            </div>
                            <div className={styles.price}>
                                MRP
                            </div>
                            <div className={styles.quantity}>
                                Quantity
                            </div>
                            <div className={styles.finalPrice}>
                                Final Price
                            </div>
                            <div className={styles.action}>
                                Action
                            </div>
                        </div>

                        <div className={styles.tableBodyWrapper}>
                            {cartItems?.map((item, index) => (
                                <EachCartItemRow item={item} key={index} count={index} onItemDeletion={getBulkOrderCartStats} getBillingSummary={getBillingSummary} />
                            ))}

                        </div>


                    </div>
                    <div className={styles.rightWrapper}>
                        <div className={styles.rwHeader}>
                            Billing Summary & Client Setup
                        </div>
                        <div className={styles.rwBody}>
                            <div>
                                <div className={styles.sectionTitle}>
                                    Billing Summary
                                </div>
                                <div>
                                </div>
                                <div>

                                    <div className={styles.grandTotalPrice}>
                                        <div>Total Items ({billingDetails?.totalItems})</div>
                                        <div>₹{billingDetails?.grandTotalPrice}</div>
                                    </div>
                                    <div className={styles.grandTotalGst}>
                                        <div>Total GST</div>
                                        <div>₹{billingDetails?.grandTotalGst}</div>
                                    </div>
                                    <div className={styles.grandTotalWithGst}>
                                        <div>Total Amount </div>
                                        <div>₹{billingDetails?.grandTotalWithGst}</div>
                                    </div>
                                </div>

                            </div>


                            <div>
                                <div className={styles.sectionTitle}>
                                    Client Details
                                </div>

                                {selectedClient === null ?
                                    <div className={styles.selectClientWrapper}>
                                        <select onChange={(e) => setSelectedClient(e.target.value)} >
                                            <option value={null}>Select A Client</option>
                                            {clientsData?.map((item, index) => (
                                                <option key={index} value={JSON.stringify(item)} >
                                                    {item?.brandName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    :
                                    <div className={styles.selectedClientWrapper} >
                                        <div className={styles.selectedClientContainer}>
                                            <div>
                                                <div className={styles.brandName}>
                                                    {JSON.parse(selectedClient)?.brandName}
                                                </div>
                                                <div className={styles.firmName}>
                                                    {JSON.parse(selectedClient)?.firmName}
                                                </div>
                                                <div className={styles.officeAddress}>
                                                    {JSON.parse(selectedClient)?.officeAddress}
                                                </div>
                                            </div>
                                            <div className={styles.changeBtn} onClick={() => setSelectedClient(null)}>
                                                Change
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>

                        </div>
                        <div className={styles.rwFooter}>
                            {isLoading === true ?
                                <button>Placing Order...</button>
                                :
                                <button onClick={() => placeBulkOrder()}>Place Order</button>
                            }
                        </div>

                    </div>

                </div>

            </div>
        </div>
    )
}

export default Cart