import React, { useContext, useState } from 'react'
import styles from './Login.module.css'
import { Link } from 'react-router-dom'
import AuthContext from '../../Store/auth-context'
import { BASE_URL_API } from '../../References/urls'

const Login = () => {
    const authCtx = useContext(AuthContext)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [isAuthenticating, setIsAuthenticating] = useState(false)

    const loginHandler = async () => {
        setIsAuthenticating(true)
        const authResponse = await fetch(BASE_URL_API + "/auth",
            {
                method: "POST",
                body: JSON.stringify({
                    username: username,
                    password: password
                })
            })
        if (!authResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const authRespo = await authResponse.json()
            if (authRespo.status === "success") {
                // authCtx.login("authRespo.authToken", "authRespo.userIdentifier")
                authCtx.login(authRespo?.authRespo?.authToken, authRespo?.authRespo?.userIdentifier)
            } else {
                alert("Please enter a valid credentials")
            }
            console.log(username, password, authRespo)
        }
        setIsAuthenticating(false)
    }



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.formWrapper}>
                    <div className={styles.logoIconWrapper}>
                        <div className={styles.qnnoxLogo}>
                            <img src="https://stylelink.s3.ap-south-1.amazonaws.com/qnnox-assets/master-file-Qnnox-logo_512x512.png" />
                        </div>
                        <div className={styles.cpanelLogo}>
                            <img src="https://static-00.iconduck.com/assets.00/cpanel-icon-2048x453-jext6iss.png" />
                        </div>
                    </div>
                    <div className={styles.fieldWrapper}>
                        <div className={styles.eachField}>
                            <input placeholder="Please enter username" autoFocus value={username} onChange={(e) => setUsername(e.target.value)} />
                        </div>
                        <div className={styles.eachField}>
                            <input placeholder="Please enter password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className={styles.loginBtnWrapper}>
                            {isAuthenticating === true ?
                                <button>Authenticating...</button>
                                :
                                <button onClick={() => loginHandler()} >Login</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login