import React from 'react'
import styles from './EachRowAllProducts.module.css'


const EachRowAllProducts = (props) => {
    const { item, editingItemHandler } = props
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.id}>
                {item?.id}.
            </div>
            <div className={styles.title}>
                {item?.title}
            </div>
            <div className={styles.brand}>
                {item?.brand}
            </div>
            <div className={styles.category}>
                {item?.category}
            </div>
            <div className={styles.description}>
                {item?.description}
            </div>
            <div className={styles.pointsRequired}>
                {item?.pointsRequired}
            </div>
            <div className={styles.editBtnWrapper}>
                <button onClick={() => editingItemHandler(item)} >Edit</button>
            </div>
        </div>
    )
}

export default EachRowAllProducts