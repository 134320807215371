import React from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import styles from './AnalyticsSummary.module.css'

const AnalyticsSummary = () => {
    return (
        <div>
            <MainHeader />
            AnalyticsSummary
        </div>
    )
}

export default AnalyticsSummary