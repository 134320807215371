import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import MainHeader from '../../Components/Headers/MainHeader'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './CreateNewOrder.module.css'

const CreateNewOrder = () => {
    const [isCreating, setIsCreating] = useState(false)
    const authCtx = useContext(AuthContext)
    const navigate = useNavigate()

    const createANewOrder = async () => {
        setIsCreating(true)
        const createANewOrderResponse = await fetch(BASE_URL_API+"/createANewOrder",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token
            })
        });

        if(!createANewOrderResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const createANewOrderRespo = await createANewOrderResponse.json()
            if(createANewOrderRespo.status==="success") {
                navigate(`/marketplace/create-order/${createANewOrderRespo.response}`)
            } else {
                if(createANewOrderRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    
                }
            }
            console.log(createANewOrderRespo)
        }
        setIsCreating(false)
    }


    return (
        <div>
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.createNewOrderCard}>
                    <div>
                        Create a new order
                    </div>
                    <div className={styles.createBtn}>
                        {isCreating === true ?
                            <button>Creating...</button>
                            :
                            <button onClick={() => createANewOrder()}>Create</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateNewOrder