import { useEffect, useRef } from 'react';
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';

const QRCodeRenderer = ({ value, onQRCodeGenerated }) => {
    const qrCodeRef = useRef(null);

    useEffect(() => {
        if (qrCodeRef.current) {
            html2canvas(qrCodeRef.current, { backgroundColor: 'white' }).then(canvas => {
                console.log('Canvas:', canvas); // Log the canvas object
                canvas.toBlob(blob => {
                    // Pass the generated blob to the parent component
                    if (typeof onQRCodeGenerated === 'function') {
                        onQRCodeGenerated(blob);
                    }
                }, 'image/png');
            });
        }
    }, [value, onQRCodeGenerated]); // Run effect whenever the value or onQRCodeGenerated function changes

    return (
        <div ref={qrCodeRef}>
            <QRCode value={value} />
        </div>
    );
};

export default QRCodeRenderer;
