import React, { useContext, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './AddClientProducts.module.css'

const AddClientProducts = (props) => {
    const { isOpen, toggleAddingProduct, onSuccess } = props
    const authCtx = useContext(AuthContext)
    const [isAdding, setIsAdding] = useState(false)
    const [productTitle, setProductTitle] = useState('')
    const [productDescription, setProductDescription] = useState('')
    const [brandName, setBrandName] = useState('')
    const [productCategory, setProductCategory] = useState('')
    const [mrp, setMrp] = useState('')

    const addClientProducts = async () => {
        setIsAdding(true)
        const addClientProductsResponse = await fetch(BASE_URL_API + "/addClientProducts",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    productTitle: productTitle,
                    productDescription: productDescription,
                    brandName: brandName,
                    productCategory: productCategory,
                    mrp: mrp
                })
            });

        if (!addClientProductsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const addClientProductsRespo = await addClientProductsResponse.json()
            if (addClientProductsRespo.status === "success") {
                onSuccess()
            } else {
                if (addClientProductsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(addClientProductsRespo)
        }
        setIsAdding(false)
    }




    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            <div>
                                Add A New Product
                            </div>
                            <div>
                                <button onClick={() => toggleAddingProduct()}>Close</button>
                            </div>
                        </div>
                        <div className={styles.mainFormWrapper}>
                            <div className={styles.eachField}>
                                <div className={styles.fieldTitle}>

                                </div>
                                <div className={styles.fieldBoxWrapper}>
                                    <input placeholder="Product Title" autoFocus={true} onChange={(e) => setProductTitle(e.target.value)} value={productTitle} />
                                </div>
                            </div>
                            <div className={styles.eachField}>
                                <div className={styles.fieldTitle}>

                                </div>
                                <div className={styles.fieldBoxWrapper}>
                                    <textarea placeholder="Product Description" onChange={(e) => setProductDescription(e.target.value)} value={productDescription} />
                                </div>
                            </div>
                            <div className={styles.eachField}>
                                <div className={styles.fieldTitle}>

                                </div>
                                <div className={styles.fieldBoxWrapper}>
                                    <input placeholder="Brand Name" onChange={(e) => setBrandName(e.target.value)} value={brandName} />
                                </div>
                            </div>
                            <div className={styles.eachField}>
                                <div className={styles.fieldTitle}>

                                </div>
                                <div className={styles.fieldBoxWrapper}>
                                    <input placeholder="Product Category" onChange={(e) => setProductCategory(e.target.value)} value={productCategory} />
                                </div>
                            </div>
                            <div className={styles.eachField}>
                                <div className={styles.fieldTitle}>

                                </div>
                                <div className={styles.fieldBoxWrapper}>
                                    <input placeholder="MRP" onChange={(e) => setMrp(e.target.value)} value={mrp} />
                                </div>
                            </div>

                            {isAdding === false ?
                                <div className={styles.actionWrapper}>

                                    <div>
                                        <button className={styles.cancelBtn}>Cancel</button>
                                    </div>
                                    <div>

                                        <button className={styles.submitBtn} onClick={() => addClientProducts()}>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className={styles.actionWrapper}>
                                    <div>
                                        <button className={styles.submittingBtn} >
                                            Submitting product details....
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default AddClientProducts