import React from 'react'
import styles from './EachCodeRow.module.css'
import QRCodeGenerator from './QRCodeGenerator'

import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";


const EachCodeRow = (props) => {
    const { item, count, isSelected, selectionHandler } = props
    return (
        <div className={styles.rowWrapper}>
            <div className={styles.sn}>
                {count + 1}
            </div>
            <div onClick={() => selectionHandler(item?.codeIdentifier)} className={styles.checkBoxWrapper} >
                {isSelected === true ? <MdCheckBox size={22} /> : <MdCheckBoxOutlineBlank size={22} />}
            </div>
            <div className={styles.codeWrapper}>
                <QRCodeGenerator value={item?.codeIdentifier} />
            </div>
            <div className={styles.mappedWithProduct}>
                {item?.mappedWithProduct}
            </div>
            <div className={styles.hasScanned}>{item?.hasScanned==='1' ? `Yes` : `No`}</div>
            <div className={styles.scannedOn}>{item?.hasScanned==='1' ? item?.scannedOn : ''}</div>
        </div>
    )
}

export default EachCodeRow