import React, { useContext, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './EditProduct.module.css'

const EditProduct = (props) => {
    const { isOpen, item, closeEditingItem, onCompletion } = props
    const authCtx = useContext(AuthContext)

    const [title, setTitle] = useState(item?.title)
    const [description, setDescription] = useState(item?.description)
    const [pointsRequired, setPointsRequired] = useState(item?.pointsRequired)
    const [stock, setStock] = useState(item?.stock)
    const [brand, setBrand] = useState(item?.brand)
    const [category, setCategory] = useState(item?.category)
    const [thumbnail, setThumbnail] = useState(item?.thumbnail)
    const [isUpdating, setIsUpdating] = useState(false)


    const updateProductHandler = async () => {
        setIsUpdating(true)
        const updateEcomProductResponse = await fetch(BASE_URL_API+"/updateEcomProduct",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                productId : item?.id,
                title : title,
                description : description,
                pointsRequired : pointsRequired,
                stock : stock,
                brand : brand,
                category : category,
                thumbnail : thumbnail,
            })
        });

        if(!updateEcomProductResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateEcomProductRespo = await updateEcomProductResponse.json()
            if(updateEcomProductRespo.status==="success") {
                onCompletion()
                closeEditingItem()
            } else {
                if(updateEcomProductRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    alert("Could not update the item records, please try again!")
                }
            }

            console.log(updateEcomProductRespo)
        }
        setIsUpdating(true)

    }

    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.pageTitle}>
                        Add Item
                    </div>
                    <div className={styles.editingFormWrapper}>
                        <div className={styles.leftFormWrapper}>
                            <div className={styles.title}>
                                <div className={styles.titleCaption}>Product Title</div>
                                <input placeholder="title" value={title} onChange={(e) => setTitle(e.target.value)} />
                            </div>
                            <div className={styles.description}>
                                <div className={styles.titleCaption}>Product Description</div>
                                <textarea value={description} onChange={(e) => setDescription(e.target.value)} >

                                </textarea>
                            </div>
                            <div className={styles.twoInRow}>
                                <div className={styles.pointsRequired}>
                                    <div className={styles.titleCaption}>Points Required</div>
                                    <input placeholder="points" value={pointsRequired} onChange={(e) => setPointsRequired(e.target.value)} />
                                </div>
                                <div className={styles.stock}>
                                    <div className={styles.titleCaption}>Stock</div>
                                    <input placeholder="stock" value={stock} onChange={(e) => setStock(e.target.value)} />
                                </div>
                            </div>

                        </div>
                        <div className={styles.rightFormWrapper}>
                            <div className={styles.category}>
                                <div className={styles.titleCaption}>Category</div>
                                <input placeholder="category" value={category} onChange={(e) => setCategory(e.target.value)} />
                            </div>

                            <div className={styles.thumbnail}>
                                <div className={styles.titleCaption}>Thumbnail</div>
                                <input placeholder="thumbnail" value={thumbnail} onChange={(e) => setThumbnail(e.target.value)} />
                            </div>


                            <div className={styles.actionWrapper}>
                                <div className={styles.cancelBtn}>
                                    <button onClick={() => closeEditingItem()}>Cancel</button>
                                </div>
                                <div className={styles.saveBtn}>
                                    <button onClick={() => updateProductHandler()}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditProduct