import React, { useContext } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './ClientAuthEachRow.module.css'

const ClientAuthEachRow = (props) => {
    const { item, count, getAllClientAuth } = props
    const authCtx = useContext(AuthContext)

    const revokeClientAuthLogin = async () => {
        const revokeClientAuthLoginResponse = await fetch(BASE_URL_API + "/revokeClientAuthLogin",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    username: item?.username
                })
            });

        if (!revokeClientAuthLoginResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const revokeClientAuthLoginRespo = await revokeClientAuthLoginResponse.json()
            if (revokeClientAuthLoginRespo.status === "success") {
                getAllClientAuth()
                alert("Login Updated")
            } else {
                if (revokeClientAuthLoginRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(revokeClientAuthLoginRespo)
        }
    }


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.sn}>
                {count}.
            </div>
            <div className={styles.username}>
                {item?.username}
            </div>
            <div className={styles.createdOn}>
                {item?.createdOn?.substring(0, 10)}
            </div>
            <div className={styles.activeSince}>
                {item?.isLoginEnabled === "1" ? "Active" : "Login Disabled"}
            </div>
            <div className={styles.action}>
                {item?.isLoginEnabled === "1" ?
                    <button onClick={() => revokeClientAuthLogin()}>Revoke Login</button>
                    :
                    <button onClick={() => revokeClientAuthLogin()}>Enable Login</button>
                }

            </div>
        </div>
    )
}

export default ClientAuthEachRow