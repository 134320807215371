import React, { useContext, useEffect, useState } from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './Kpis.module.css'

const Kpis = () => {

    const authCtx = useContext(AuthContext)
    const [ordersData, setOrdersData] = useState([])
    const [gmvData, setGmvData] = useState([])

    const getBulkOrders = async () => {
        const getBulkOrdersResponse = await fetch(BASE_URL_API+"/getBulkOrders",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token
            })
        });

        if(!getBulkOrdersResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getBulkOrdersRespo = await getBulkOrdersResponse.json()
            if(getBulkOrdersRespo.status==="success") {
                setOrdersData(getBulkOrdersRespo.response)
                getTotalGmv()
            } else {
                if(getBulkOrdersRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setOrdersData([])
                }
            }
            console.log(getBulkOrdersRespo)
        }
    }

    const getTotalGmv = async () => {
        const getTotalGmvResponse = await fetch(BASE_URL_API+"/getTotalGmv",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token
            })
        });

        if(!getTotalGmvResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getTotalGmvRespo = await getTotalGmvResponse.json()
            if(getTotalGmvRespo.status==="success") {
                setGmvData(getTotalGmvRespo.response)
            } else {
                if(getTotalGmvRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setGmvData([])
                }
            }
            console.log(getTotalGmvRespo)
        }
    }

    useEffect(() => {
        getBulkOrders()
    }, [])




    return (
        <div>
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.eachCardWrapper}>
                        <div>
                            Total Orders
                        </div>

                        <div className={styles.value}>
                            {ordersData?.length}
                        </div>
                    </div>
                    
                    <div className={styles.eachCardWrapper}>
                        <div>
                            Total GMV
                        </div>

                        <div className={styles.value}>
                            ₹{gmvData?.totalGmv}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Kpis