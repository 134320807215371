import React from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import styles from './AnalyticsUser.module.css'

export const AnalyticsUser = () => {
    return (
        <div>
            <MainHeader /> 
            AnalyticsUser
        </div>
    )
}
