import React, { useContext, useState } from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './CreateNewSchemeNOffers.module.css'

const CreateNewSchemeNOffers = () => {
    const authCtx = useContext(AuthContext)
    const [schemeTitle, setSchemeTitle] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [description, setDescription] = useState('')
    const [totalSpots, setTotalSpots] = useState('')
    const [schemeBanner, setSchemeBanner] = useState('')
    const [isCreatingScheme, setIsCreatingScheme] = useState(false)


    const clearFormHandler = () => {
        setSchemeTitle('')
        setStartDate('')
        setEndDate('')
        setDescription('')
        setTotalSpots('')
        setSchemeBanner('')
    }

    const createSchemeHandler = async () => {
        setIsCreatingScheme(true)
        const createSchemeAndOffersResponse = await fetch(BASE_URL_API + "/createSchemeAndOffers",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    schemeTitle: schemeTitle,
                    startDate: startDate,
                    endDate: endDate,
                    description: description,
                    totalSpots: totalSpots,
                    schemeBanner: schemeBanner
                })
            });

        if (!createSchemeAndOffersResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const createSchemeAndOffersRespo = await createSchemeAndOffersResponse.json()
            if (createSchemeAndOffersRespo.status === "success") {
                clearFormHandler()
            } else {
                if (createSchemeAndOffersRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    alert(createSchemeAndOffersRespo.message)
                }
            }
            console.log(createSchemeAndOffersRespo)
        }
        setIsCreatingScheme(false)
    }

    return (
        <div>
            <MainHeader />
            <div className={styles.mainWrapper}>
                <div className={styles.formWrapper}>
                    <div className={styles.formContainer}>
                        <div className={styles.eachFieldWrapper}>
                            <div>schemeTitle</div>
                            <div className={styles.eachFieldInput}>
                                <input placeholder="schemeTitle" autoFocus={true} value={schemeTitle} onChange={(e) => setSchemeTitle(e.target.value)} />
                            </div>
                        </div>
                        <div className={styles.twoInRow}>
                            <div className={styles.eachFieldWrapper}>
                                <div>startDate</div>
                                <div className={styles.eachFieldInput}>
                                    <input placeholder="startDate" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                </div>
                            </div>
                            <div className={styles.eachFieldWrapper}>
                                <div>endDate</div>
                                <div className={styles.eachFieldInput}>
                                    <input placeholder="endDate" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.eachFieldWrapper}>
                            <div>description</div>
                            <div className={styles.eachFieldInput}>
                                <textarea placeholder="description" value={description} onChange={(e) => setDescription(e.target.value)} />
                            </div>
                        </div>
                        <div className={styles.eachFieldWrapper}>
                            <div>totalSpots</div>
                            <div className={styles.eachFieldInput}>
                                <input placeholder="totalSpots" value={totalSpots} type="number" onChange={(e) => setTotalSpots(e.target.value)} />
                            </div>
                        </div>
                        <div className={styles.eachFieldWrapper}>
                            <div>schemeBanner</div>
                            <div className={styles.eachFieldInput}>
                                <input placeholder="schemeBanner" value={schemeBanner} onChange={(e) => setSchemeBanner(e.target.value)} />
                            </div>
                        </div>

                    </div>
                    <div className={styles.submitBtn}>
                        {isCreatingScheme === true ?
                            <button>Creating Schemes....</button>
                            :
                            <button onClick={() => createSchemeHandler()}>Submit</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateNewSchemeNOffers