import React from 'react'
import styles from './EachItemShipping.module.css'

const EachItemShipping = (props) => {
    const { item, count, activeItem, activeItemHandler } = props

    return (
        <div className={styles.mainWrapper} >
            <div className={styles.sn}>
                {count + 1}.
            </div>
            <div className={styles.productTitle}>
                {item?.productTitle}
            </div>
            <div className={styles.quantity}>
                {item?.quantity}
            </div>
            <div className={styles.action}>
                <button onClick={() => activeItemHandler(item)} >Update Shipping Address</button>
            </div>
            <div className={styles.action}>
                <button>Assign Vendor</button>
            </div>
        </div>
    )
}

export default EachItemShipping