import React, { useContext, useEffect, useState } from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './CreateNewUser.module.css'

const CreateNewUser = () => {
    const authCtx = useContext(AuthContext)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [firstName, setFirstName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [lastName, setLastName] = useState('')
    const [gender, setGender] = useState('')
    const [isCreating, setIsCreating] = useState(false)

    const [allClients, setAllClients] = useState([])
    const [selectedClient, setSelectedClient] = useState(null)

    const getAllClients = async () => {
        const getAllClientsResponse = await fetch(BASE_URL_API + "/getAllClients",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllClientsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllClientsRespo = await getAllClientsResponse.json();
            if (getAllClientsRespo.status === "success") {
                setAllClients(getAllClientsRespo.response)
            } else {
                if (getAllClientsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllClients([])
                }
            }
        }

    }

    useEffect(() => {
        getAllClients()
    }, [])



    const clearFormHandler = () => {
        setPhoneNumber('')
        setFirstName('')
        setMiddleName('')
        setLastName('')
        setGender('')
    }

    const createNewUser = async () => {
        if (phoneNumber.length === 10) {
            if (selectedClient !== null) {
                setIsCreating(true)
                const createNewUserResponse = await fetch(BASE_URL_API + "/createNewUser",
                    {
                        method: "POST",
                        body: JSON.stringify({
                            token: authCtx.token,
                            phoneNumber: phoneNumber,
                            firstName: firstName,
                            middleName: middleName,
                            lastName: lastName,
                            gender: gender,
                            clientIdentifier : selectedClient
                        })
                    });

                if (!createNewUserResponse.ok) {
                    console.log("Something went wrong : Server Error")
                } else {
                    const createNewUserRespo = await createNewUserResponse.json()
                    if (createNewUserRespo.status === "success") {
                        clearFormHandler()
                    } else {
                        if (createNewUserRespo.message === "token Expired, please login again!") {
                            authCtx.logout()
                        } else {
                            alert(createNewUserRespo.message)
                        }
                    }
                    console.log(createNewUserRespo)
                    setIsCreating(false)
                }
            } else {
                alert("Please select a client")
            }

        } else {
            alert("Please enter 10 digit phone number")
        }

    }


    return (
        <div>
            <MainHeader />
            <div className={styles.mainWrapper}>
                <div className={styles.mainTitle}>
                    Create A New User
                </div>
                <div className={styles.formWrapper}>
                    <div className={styles.selectClientWrapper}>
                        <select onChange={(e) => setSelectedClient(e.target.value)} >
                            <option value={null}>Select A Client</option>
                            {allClients?.map((item, index) => (
                                <option value={item?.clientIdentifier}>{item?.brandName}</option>
                            ))}
                        </select>
                    </div>
                    <div className={styles.formContainer}>
                        <div className={styles.formFieldsWrapper}>
                            <div className={styles.eachRowWrapper}>
                                <div>Phone </div>
                                <div className={styles.eachInputWrapper}>
                                    <input value={phoneNumber} type="number" autoFocus onChange={(e) => setPhoneNumber(e.target.value)} />
                                </div>
                            </div>

                            <div className={styles.threeInRow}>
                                <div>
                                    <div>First Name </div>
                                    <div className={styles.eachInputWrapper}>
                                        <input value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                    </div>
                                </div>
                                <div>
                                    <div>Middle Name </div>
                                    <div className={styles.eachInputWrapper}>
                                        <input value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
                                    </div>
                                </div>
                                <div>
                                    <div>Last Name </div>
                                    <div className={styles.eachInputWrapper}>
                                        <input value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.eachRowWrapper}>
                                <div>Gender</div>
                                <div className={styles.genderWrapper}>
                                    <button className={gender === "Female" ? styles.eachGenderSelected : styles.eachGender} onClick={() => setGender('Female')}>Female </button>
                                    <button className={gender === "Male" ? styles.eachGenderSelected : styles.eachGender} onClick={() => setGender('Male')}>Male </button>
                                    <button className={gender === "LGBTQ" ? styles.eachGenderSelected : styles.eachGender} onClick={() => setGender('LGBTQ')}>LGBTQ </button>
                                    <button className={gender === "Unknown" ? styles.eachGenderSelected : styles.eachGender} onClick={() => setGender('Unknown')}>Unknown </button>
                                </div>
                            </div>

                        </div>
                        <div>
                            {isCreating === true ?
                                <button>Creating now...</button>
                                :
                                <button onClick={() => createNewUser()}>Create Now</button>
                            }
                        </div>
                    </div>


                </div>
            </div>

        </div>
    )
}

export default CreateNewUser