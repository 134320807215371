import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AddClientProducts from '../../Components/Clients/AddClientProducts'
import EachProductRow from '../../Components/Clients/EachProductRow'
import MainHeader from '../../Components/Headers/MainHeader'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './ClientProducts.module.css'

const ClientProducts = () => {
    const authCtx = useContext(AuthContext)

    const [allProducts, setAllProducts] = useState([])
    const [gettingAllProducts, setGettingAllProducts] = useState(false)
    const [isAddingNewProduct, setIsAddingNewProduct] = useState(false)
    const toggleAddingProduct = () => setIsAddingNewProduct(false)

    const getAllProducts = async () => {
        setIsAddingNewProduct(false)
        setGettingAllProducts(true)
        const getAllProductsResponse = await fetch(BASE_URL_API + "/getAllProducts",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllProductsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllProductsRespo = await getAllProductsResponse.json()
            if (getAllProductsRespo.status === "success") {
                setAllProducts(getAllProductsRespo.response)
            } else {
                if (getAllProductsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllProducts([])
                }
            }
        }
        setGettingAllProducts(false)
    }

    useEffect(() => {
        getAllProducts()
    }, [])



    return (
        <div>
            <AddClientProducts isOpen={isAddingNewProduct} toggleAddingProduct={toggleAddingProduct} onSuccess={getAllProducts} />
            <MainHeader />
            <div className={styles.mainBodyWrapper}>
                <div className={styles.mainActionWrapper}>
                    <div className={styles.titleWrapper}>
                        <div>
                            View All Products
                        </div>
                        <div>
                            <button
                                className={styles.addNewProduct}
                                onClick={() => setIsAddingNewProduct(true)}
                            >
                                Add New Product
                            </button>
                        </div>
                    </div>
                    <div className={styles.filterWrapper}>
                        <div>
                            <button>All Products</button>
                        </div>
                    </div>
                </div>
                <div className={styles.tableHeader}>
                    <div className={styles.sn}>
                        #
                    </div>
                    <div className={styles.productIdentifier}>
                        Product ID.
                    </div>
                    <div className={styles.productTitle}>
                        Product Title
                    </div>
                    <div className={styles.productDescription}>
                        Product Description
                    </div>
                    <div className={styles.codeIdentifier}>
                        Brand
                    </div>
                    <div className={styles.mrp}>
                        MRP
                    </div>
                    <div className={styles.actionWrapper}>
                        Action
                    </div>
                </div>
                <div className={styles.tableBody}>
                    {allProducts.map((item, index) => (
                        <EachProductRow key={index} count={index} item={item} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ClientProducts