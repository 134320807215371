import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';

import styles from './QRCodeGenerator.module.css'


const QRCodeGenerator = ({ value }) => {
  const downloadQRCode = () => {
    domtoimage.toBlob(document.getElementById(`${value}-qr-code`))
      .then(function (blob) {
        saveAs(blob, `${value}.png`);
      });
  };

  return (
    <div className={styles.mainWrapper}>
      <div id={`${value}-qr-code`} className={styles.qrcodeWrapper}>
        <QRCode size={80} value={value} />
      </div>
      <div className={styles.downloadBtn}>
        <button  onClick={downloadQRCode}>Download QR</button>
      </div>
    </div>
  );
};

export default QRCodeGenerator;
