import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import GenerateQRCodesNow from '../../Components/QRCode/GenerateQRCodesNow'
import DownloadQRCodes from '../../Components/QRCode/DownloadQRCodes'
import styles from './GenerateQRCodes.module.css'
import MapQRCodes from '../../Components/QRCode/MapQRCodes'
import { MdCreateNewFolder, MdFileDownload, MdInsertLink } from 'react-icons/md'
import MainHeader from '../../Components/Headers/MainHeader'



const GenerateQRCodes = () => {
    const [activeNav, setActiveNav] = useState('Generate')


    const activeNavHandler = (k) => {
        setActiveNav(k)
    }


    return (
        <div>
            <MainHeader />
            <div className={styles.mainBodyWrapper}>
                <div className={styles.mainBodyContainer}>
                    <div className={styles.navColWrapper}>
                        <div className={styles.navColContainer}>
                            <div onClick={() => activeNavHandler('Generate')} className={styles.eachNavBtn} >
                                <div className={styles.eachNavIcon}>
                                    <MdCreateNewFolder size={34} />
                                </div>
                                <div className={styles.eachNavTxt}>
                                    Generate
                                </div>
                            </div>
                            <div onClick={() => activeNavHandler('Map')} className={styles.eachNavBtn} >
                                <div className={styles.eachNavIcon}>
                                    <MdFileDownload size={34} />
                                </div>
                                <div className={styles.eachNavTxt}>
                                    Download
                                </div>
                            </div>
                            
                            <div onClick={() => activeNavHandler('Map')} className={styles.eachNavBtn} >
                                <div className={styles.eachNavIcon}>
                                    <MdInsertLink size={34} />
                                </div>
                                <div className={styles.eachNavTxt}>
                                    Map
                                </div>
                            </div>
                            
                            
                        </div>

                    </div>
                    <div className={styles.contentWrapper}>
                        {activeNav === 'Generate' && <GenerateQRCodesNow onSuccess={() => activeNavHandler('Map')} />}
                        {activeNav === 'Map' && <MapQRCodes />}
                        {activeNav === 'Download' && <DownloadQRCodes />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GenerateQRCodes